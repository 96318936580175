import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Alert,
  AlertIcon,
  Text,
  useToast,
  Image,
} from "@chakra-ui/react";
import { createContactSettings, updateContactSettings } from "src/store/settings/contact/contactSettingsSlice";

const Main = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const dispatch = useDispatch();
  const contactSettings = useSelector(state => state.contactSettings);

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
    handleSubmit
  } = useForm({
    defaultValues: contactSettings.data
  });

  watch(["background"]);

  return (
    <form onSubmit={handleSubmit(values => {
      delete values._id;
      const formData = new FormData();
      for (let prop in values) {
        if (prop === "background") {
          formData.set("background", typeof (values.background) === "string" ? values.background : values.background[0]);

        } else {
          formData.set(prop, values[prop])
        }
      }
      dispatch(contactSettings ? updateContactSettings(formData) : createContactSettings(formData))
        .unwrap()
        .then(_ => {
          toast({ description: "settings updated" });
        })
        .catch(e => {
          window.scrollTo(0, 0);
        })
    })}>
      <Stack spacing={6}>
        {contactSettings.error && (
          <Alert status="error" variant="left-accent">
            <AlertIcon />
            <Text>{contactSettings.error || "-"}</Text>
          </Alert>
        )}

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.contact.main.title")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.contact.main.title")}
            height="48px" borderRadius={4}
            {...register("title", {
              required: t("validation.required")
            })}
          />
          {errors.title && (
            <FormHelperText color="red.600">{errors.title.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.contact.main.phone")}
          </FormLabel>
          <Input
            type="tel" placeholder={t("pages.settings.contact.main.phone")}
            height="48px" borderRadius={4}
            {...register("phone", {
              required: t("validation.required")
            })}
          />
          {errors.phone && (
            <FormHelperText color="red.600">{errors.phone.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.contact.main.background")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.contact.main.background")}
            height="48px" borderRadius={4} pt={2}
            {...register("background")}
          />
          {typeof (getValues("background")) === "string" && getValues("background") && (
            <>
              <Image mt={2} src={getValues("background")} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("background", null)}>
                {t("general.delete")}
              </Button>
            </>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.contact.main.phone_description")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.contact.main.phone_description")}
            height="48px" borderRadius={4}
            {...register("phoneDescription", {
              required: t("validation.required")
            })}
          />
          {errors.phoneDescription && (
            <FormHelperText color="red.600">{errors.phoneDescription.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.contact.main.address")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.contact.main.address")}
            height="48px" borderRadius={4}
            {...register("address", {
              required: t("validation.required")
            })}
          />
          {errors.address && (
            <FormHelperText color="red.600">{errors.address.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.contact.main.email")}
          </FormLabel>
          <Input
            type="email" placeholder={t("pages.settings.contact.main.email")}
            height="48px" borderRadius={4}
            {...register("email", {
              required: t("validation.required")
            })}
          />
          {errors.email && (
            <FormHelperText color="red.600">{errors.email.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.contact.main.email_description")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.contact.main.email_description")}
            height="48px" borderRadius={4}
            {...register("emailDescription", {
              required: t("validation.required")
            })}
          />
          {errors.emailDescription && (
            <FormHelperText color="red.600">{errors.emailDescription.message}</FormHelperText>
          )}
        </FormControl>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          textTransform="capitalize"
          _hover={{ bg: "green.600" }}
        >
          {t("general.save")}
        </Button>
      </Stack>
    </form>
  )
}

export default Main