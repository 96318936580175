import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Flex, Text, InputGroup, Input, InputRightElement, Alert, AlertIcon } from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';

import Pagination from 'src/components/shared/pagination/Pagination';
import Breadcrumbs from 'src/components/shared/breadcrumbs/Breadcrumbs';
import JoinTable from './Table';

import { getJoin } from 'src/store/join/joinSlice';

import { Wrapper } from './Styles';

import theme from '../../global/theme';

const Join = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const join = useSelector(state => state.join);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");

  useEffect(() => {
    dispatch(getJoin({ page, query }));
  }, [dispatch, page, query]);


  return (
    <Wrapper>
      <Breadcrumbs
        currentPage={t('pages.join.join')}
        pages={[{ name: `${t('pages.dashboard.dashboard')}`, path: '/' }]}
      />

      {join.error && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          <Text>{join.error}</Text>
        </Alert>
      )}

      <Flex marginBottom={8} justifyContent="flex-end">
        <InputGroup width="auto">
          <Input auth="auto" type="text" placeholder={t('general.search')}
            bg={theme.light} boxShadow={theme.shadow} color={theme.dark} border="none"
            onChange={(e) => setQuery(e.currentTarget.value)}
          />
          <InputRightElement color={theme.light} children={<FiIcons.FiSearch />} />
        </InputGroup>
      </Flex>

      {join.itemsCount > 0 ? (
        <JoinTable
          data={join.data}
          page={page}
        />
      ) : (
        <Flex textAlign="center" bg={theme.light} boxShadow={theme.shadow} height={200} alignItems="center"
          justifyContent="center" borderRadius={16}>
          <Text fontSize={18} textTransform="capitalize" color="gray.300" fontWeight="bold">
            {t('pages.join.no_join')}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={join.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>
    </Wrapper>
  )
}

export default Join