import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: {},
  error: null,
}

export const getDreamShotSettings = createAsyncThunk(
  "servicesSettings/getDreamShotSettings",
  async (_, thunkApi) => {
    try {
      const { data } = await axiosInstace.get("/dreamshot");
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createDreamShotSettings = createAsyncThunk(
  "servicesSettings/createDreamShotSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/dreamshot", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateDreamShotSettings = createAsyncThunk(
  "servicesSettings/updateDreamShotSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put("/dreamshot", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const servicesSettingsSlice = createSlice({
  name: "servicesSettings",
  initialState,
  extraReducers: (builder) => {
    builder

      .addCase(getDreamShotSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDreamShotSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(getDreamShotSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(createDreamShotSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createDreamShotSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(createDreamShotSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateDreamShotSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateDreamShotSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(updateDreamShotSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
  }
});

export default servicesSettingsSlice.reducer;