import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  itemsCount: 0,
  error: null,
}

export const getSalaries = createAsyncThunk(
  "salaries/getSalaries",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const { data } = await axiosInstace.get(`/salaries?page=${page}&size=${size}&query=${query}`,);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createSalary = createAsyncThunk(
  "salaries/createSalary",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("salaries", args);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateSalary = createAsyncThunk(
  "salaries/updateSalary",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put(`/salaries/${args._id}`, args.values);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteSalary = createAsyncThunk(
  "salaries/deleteSalary",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.delete(`/salaries/${args._id}`);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
)

const salariesSlice = createSlice({
  name: "salaries",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getSalaries.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSalaries.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = payload.data.data;
        state.itemsCount = payload.data.itemsCount;
        state.pages = payload.data.pages;
      })
      .addCase(getSalaries.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(createSalary.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createSalary.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data.push(payload.data);
      })
      .addCase(createSalary.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateSalary.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateSalary.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.isLoading = false;
        state.error = null;
        const indexAt = state.data?.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
      })
      .addCase(updateSalary.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(deleteSalary.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteSalary.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = state.data.filter(el => el._id !== payload.data._id);
      })
      .addCase(deleteSalary.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
  }
});

export default salariesSlice.reducer;