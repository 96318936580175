import { configureStore } from '@reduxjs/toolkit';

// general
import sidebarSlice from './sidebar/sidebarSlice';

// pages
import authSlice from "./auth/authSlice";
import homeSettingsSlice from "./settings/home/homeSettingsSlice";
import aboutSettingsSlice from "./settings/about/aboutSettingsSlice";
import blogsSettingsSlice from "./settings/blogs/blogsSettingsSlice";
import studioSettingsSlice from "./settings/studio/studioSettingsSlice";
import contactSettingsSlice from "./settings/contact/contactSettingsSlice";
import saleSettingsSlice from "./settings/sale/saleSettingsSlice";
import servicesSettingsSlice from "./settings/services/servicesSettingsSlice";
import usersSlice from "./users/usersSlice";
import blogsSlice from "./blogs/blogsSlice";
import reviewsSlice from "./reviews/reviewsSlice";
import clientsSlice from "./clients/clientsSlice";
import featuresSlice from "./features/featuresSlice";
import plansSlice from "./plans/plansSlice";
import portofolioSlice from "./portofolio/portofolioSlice";
import contactsSlice from "./contacts/contactsSlice";
import reservationsSlice from "./reservations/reservationsSlice";
import salariesSlice from "./salaries/salariesSlice";
import expensesSlice from "./expenses/expensesSlice";
import servicesSlice from "./services/servicesSlice";
import reportsSlice from "./reports/reportsSlice";
import codesSlice from "./codes/codesSlice";
import dreamShotSettingsSlice from "./settings/dreamShot/dreamShotSettingsSlice";
import joinSlice from "./join/joinSlice";

export default configureStore({
  reducer: {
    sidebar: sidebarSlice,
    auth: authSlice,
    homeSettings: homeSettingsSlice,
    aboutSettings: aboutSettingsSlice,
    blogsSettings: blogsSettingsSlice,
    studioSettings: studioSettingsSlice,
    contactSettings: contactSettingsSlice,
    saleSettings: saleSettingsSlice,
    servicesSettings: servicesSettingsSlice,
    users: usersSlice,
    blogs: blogsSlice,
    reviews: reviewsSlice,
    clients: clientsSlice,
    features: featuresSlice,
    plans: plansSlice,
    portofolio: portofolioSlice,
    contacts: contactsSlice,
    join: joinSlice,
    reservations: reservationsSlice,
    salaries: salariesSlice,
    expenses: expensesSlice,
    services: servicesSlice,
    reports: reportsSlice,
    codes: codesSlice,
    dreamShot: dreamShotSettingsSlice
  },
});