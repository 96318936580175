import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Alert,
  AlertIcon,
  Text,
  useToast,
  Textarea,
  Image,
} from "@chakra-ui/react";
import { createAboutSettings, updateAboutSettings } from "src/store/settings/about/aboutSettingsSlice";

const Main = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const dispatch = useDispatch();
  const aboutSettings = useSelector(state => state.aboutSettings);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    watch
  } = useForm({
    defaultValues: aboutSettings.data
  });

  watch(["image", "signature", "backgroundImage"])

  return (
    <form onSubmit={handleSubmit(values => {
      const formData = new FormData();

      formData.set("title", values.title);
      formData.set("mainSection[title]", values.mainSection.title);
      formData.set("mainSection[subtitle]", values.mainSection.subtitle);
      formData.set("mainSection[description]", values.mainSection.description);
      formData.set("mainSection[quote]", values.mainSection.quote);
      formData.set("mainSection[statistics][photosTaken]", values.mainSection.statistics.photosTaken);
      formData.set("mainSection[statistics][creativeVideo]", values.mainSection.statistics.creativeVideo);
      formData.set("mainSection[statistics][workingHours]", values.mainSection.statistics.workingHours);
      formData.set("mainSection[statistics][happyClients]", values.mainSection.statistics.happyClients);

      if (typeof (values.backgroundImage) === "string") {
        formData.set("backgroundImage", values.backgroundImage);
      } else {
        formData.set("backgroundImage", values.backgroundImage[0]);
      }

      if (typeof (values.image) === "string") {
        formData.set("image", values.image);
      } else {
        formData.set("image", values.image[0]);
      }

      if (typeof (values.signature) === "string") {
        formData.set("signature", values.signature);
      } else {
        formData.set("signature", values.signature[0]);
      }

      dispatch(aboutSettings?.data ? updateAboutSettings(formData) : createAboutSettings(formData))
        .unwrap()
        .then(_ => {
          toast({ description: "settings updated" });
        })
        .catch(e => {
          window.scrollTo(0, 0);
        })
    })}>
      <Stack spacing={6}>
        {aboutSettings.error && (
          <Alert status="error" variant="left-accent">
            <AlertIcon />
            <Text>{aboutSettings.error || "-"}</Text>
          </Alert>
        )}

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.about.main.title")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.about.main.title")}
            height="48px" borderRadius={4}
            {...register("title", {
              required: t("validation.required")
            })}
          />
          {errors.title && (
            <FormHelperText color="red.600">{errors.title.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.about.main.background_image")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.about.main.background_image")}
            height="48px" borderRadius={4} pt={2}
            {...register("backgroundImage")}
          />
          {typeof (getValues("backgroundImage")) === "string" && getValues("backgroundImage") && (
            <>
              <Image mt={2} src={getValues("backgroundImage")} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("backgroundImage", null)}>
                {t("general.delete")}
              </Button>
            </>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.about.main.main_section_title")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.about.main.main_section_title")}
            height="48px" borderRadius={4}
            {...register("mainSection.title", {
              required: t("validation.required")
            })}
          />
          {errors.mainSection?.title && (
            <FormHelperText color="red.600">{errors.mainSection.title.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.about.main.main_section_subtitle")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.about.main.main_section_subtitle")}
            height="48px" borderRadius={4}
            {...register("mainSection.subtitle", {
              required: t("validation.required")
            })}
          />
          {errors.mainSection?.subtitle && (
            <FormHelperText color="red.600">{errors.mainSection.subtitle.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.about.main.main_section_description")}
          </FormLabel>
          <Textarea
            resize="none" placeholder={t("pages.settings.about.main.main_section_description")}
            height="48px" borderRadius={4}
            {...register("mainSection.description", {
              required: t("validation.required")
            })}
          />
          {errors.mainSection?.description && (
            <FormHelperText color="red.600">{errors.mainSection.description.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.about.main.main_section_quote")}
          </FormLabel>
          <Textarea
            resize="none" placeholder={t("pages.settings.about.main.main_section_quote")}
            height="48px" borderRadius={4}
            {...register("mainSection.quote", {
              required: t("validation.required")
            })}
          />
          {errors.mainSection?.quote && (
            <FormHelperText color="red.600">{errors.mainSection.quote.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.about.main.image")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.about.main.image")}
            height="48px" borderRadius={4} pt={2}
            {...register("image")}
          />
          {typeof (getValues("image")) === "string" && getValues("image") && (
            <>
              <Image mt={2} src={getValues("image")} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("image", null)}>
                {t("general.delete")}
              </Button>
            </>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.about.main.signature")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.about.main.signature")}
            height="48px" borderRadius={4} pt={2}
            {...register("signature")}
          />
          {typeof (getValues("signature")) === "string" && getValues("signature") && (
            <>
              <Image mt={2} src={getValues("signature")} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("signature", null)}>
                {t("general.delete")}
              </Button>
            </>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.about.main.main_section_photo_taken")}
          </FormLabel>
          <Input
            type="number" placeholder={t("pages.settings.about.main.main_section_photo_taken")}
            height="48px" borderRadius={4}
            {...register("mainSection.statistics.photosTaken", {
              required: t("validation.required")
            })}
          />
          {errors.mainSection?.statistics?.photosTaken && (
            <FormHelperText color="red.600">{errors.mainSection.statistics.photosTaken.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.about.main.main_section_creative_videos")}
          </FormLabel>
          <Input
            type="number" placeholder={t("pages.settings.about.main.main_section_creative_videos")}
            height="48px" borderRadius={4}
            {...register("mainSection.statistics.creativeVideo", {
              required: t("validation.required")
            })}
          />
          {errors.mainSection?.statistics?.creativeVideo && (
            <FormHelperText color="red.600">{errors.mainSection.statistics.creativeVideo.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.about.main.main_section_working_hours")}
          </FormLabel>
          <Input
            type="number" placeholder={t("pages.settings.about.main.main_section_working_hours")}
            height="48px" borderRadius={4}
            {...register("mainSection.statistics.workingHours", {
              required: t("validation.required")
            })}
          />
          {errors.mainSection?.statistics?.workingHours && (
            <FormHelperText color="red.600">{errors.mainSection.statistics.workingHours.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.about.main.main_section_happy_clients")}
          </FormLabel>
          <Input
            type="number" placeholder={t("pages.settings.about.main.main_section_happy_clients")}
            height="48px" borderRadius={4}
            {...register("mainSection.statistics.happyClients", {
              required: t("validation.required")
            })}
          />
          {errors.mainSection?.statistics?.happyClients && (
            <FormHelperText color="red.600">{errors.mainSection.statistics.happyClients.message}</FormHelperText>
          )}
        </FormControl>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          textTransform="capitalize"
          _hover={{ bg: "green.600" }}
        >
          {t("general.save")}
        </Button>
      </Stack>
    </form>
  )
}

export default Main