import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  itemsCount: 0,
  error: null,
}

export const getReservations = createAsyncThunk(
  "reservations/getReservations",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const { data } = await axiosInstace.get(`/reservations?page=${page}&size=${size}&query=${query}`,);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createReservation = createAsyncThunk(
  "plans/createReservation",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("reservations", args);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const blockReservation = createAsyncThunk(
  "plans/blockReservation",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("reservations/block", args);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

const reservationsSlice = createSlice({
  name: "reservations",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getReservations.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getReservations.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = payload.data.data;
        state.pages = payload.data.pages;
        state.itemsCount = payload.data.itemsCount;
      })
      .addCase(getReservations.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(createReservation.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createReservation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data.push(payload.data);
      })
      .addCase(createReservation.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(blockReservation.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(blockReservation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        // state.data.push(payload.data);
      })
      .addCase(blockReservation.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
  }
});

export default reservationsSlice.reducer;