import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Spinner, Link as ChakraLink } from "@chakra-ui/react";
import theme from "src/components/global/theme";
import { getHomeSettings } from "src/store/settings/home/homeSettingsSlice";
import Breadcrumbs from "src/components/shared/breadcrumbs/Breadcrumbs";
import { Wrapper } from "./Styles";

const Home = () => {
  const { t } = useTranslation();
  const homeSettings = useSelector(state => state.homeSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomeSettings())
  }, [dispatch]);

  const TabEl = ({ title, link }) => (
    <ChakraLink
      as={NavLink} end to={link} display="inline-flex"
      textTransform="capitalize" borderBottom="none"
      paddingInline={6} paddingBlock={3}
      _selected={{ bg: "blue.600", color: "white", borderBottom: "none", outline: "none" }}
    >
      {title}
    </ChakraLink>
  );

  const tabs = [
    { title: t("pages.settings.home.main.main"), link: "" },
    { title: t("pages.settings.home.blog.blog"), link: "blog" },
    { title: t("pages.settings.home.info.info"), link: "info" },
    { title: t("pages.settings.home.portofolio.portofolio"), link: "portofolio" },
    { title: t("pages.settings.home.video.video"), link: "video" },
    { title: t("pages.settings.home.about.about"), link: "about" },
    { title: t("pages.settings.home.services.services"), link: "services" },
    { title: t("pages.settings.home.footer.footer"), link: "footer" },
  ];


  return homeSettings.isLoading ? (
    <Flex justifyContent="center" bg="white" paddingInline={2} paddingBlock={4}>
      <Spinner />
    </Flex>
  )
    : (
      <Wrapper>
        <Breadcrumbs
          currentPage={t("pages.settings.settings")}
          pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
        />

        <Box bg="white" boxShadow={theme.shadow} borderRadius={4} overflow="hidden" position="relative">
          <Box borderBottom="1px solid #eee">
            {tabs.map((tab, index) => (
              <TabEl title={tab.title} link={tab.link} key={index} />
            ))}
          </Box>
          <Box p={4}>
            <Outlet />
          </Box>
        </Box >
      </Wrapper>
    )
}

export default Home