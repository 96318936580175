import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Alert,
  AlertIcon,
  Text,
  useToast,
} from "@chakra-ui/react";
import { updateServicesSettings } from "src/store/settings/services/servicesSettingsSlice";

const Services = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const dispatch = useDispatch();
  const servicesSettings = useSelector(state => state.servicesSettings);
  const servicesSecSettings = servicesSettings.data?.servicesSection || {};

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      name1: Array.isArray(servicesSecSettings?.services) && servicesSecSettings.services[0].name,
      name2: Array.isArray(servicesSecSettings?.services) && servicesSecSettings.services[1].name,
      name3: Array.isArray(servicesSecSettings?.services) && servicesSecSettings.services[2].name,
      name4: Array.isArray(servicesSecSettings?.services) && servicesSecSettings.services[3].name,
      name5: Array.isArray(servicesSecSettings?.services) && servicesSecSettings.services[4].name,
      name6: Array.isArray(servicesSecSettings?.services) && servicesSecSettings.services[5].name
    }
  });

  return (
    <form onSubmit={handleSubmit(values => {
      delete values._id;
      const formData = new FormData();

      formData.append("name", values.name1);
      formData.append("name", values.name2);
      formData.append("name", values.name3);
      formData.append("name", values.name4);
      formData.append("name", values.name5);
      formData.append("name", values.name6);

      for (let i = 0; i < values.servicesImages.length; i++) {
        formData.append("servicesImages", values["servicesImages"][i]);
      }

      for (let i = 0; i < values.servicesIcons.length; i++) {
        formData.append("servicesIcons", values["servicesIcons"][i]);
      }

      dispatch(updateServicesSettings(formData))
        .unwrap()
        .then(_ => {
          toast({ description: "settings updated" });
        })
        .catch(e => {
          window.scrollTo(0, 0);
        })
    })}>
      <Stack spacing={6}>
        {servicesSettings.error && (
          <Alert status="error" variant="left-accent">
            <AlertIcon />
            <Text>{servicesSettings.error || "-"}</Text>
          </Alert>
        )}

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.services.name1")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.services.services.name1")}
            height="48px" borderRadius={4}
            {...register("name1", {
              required: t("validation.required")
            })}
          />
          {errors.name1 && (
            <FormHelperText color="red.600">{errors.name1.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.services.name2")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.services.services.name2")}
            height="48px" borderRadius={4}
            {...register("name2", {
              required: t("validation.required")
            })}
          />
          {errors.name2 && (
            <FormHelperText color="red.600">{errors.name2.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.services.name3")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.services.services.name3")}
            height="48px" borderRadius={4}
            {...register("name3", {
              required: t("validation.required")
            })}
          />
          {errors.name3 && (
            <FormHelperText color="red.600">{errors.name3.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.services.name4")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.services.services.name4")}
            height="48px" borderRadius={4}
            {...register("name4", {
              required: t("validation.required")
            })}
          />
          {errors.name4 && (
            <FormHelperText color="red.600">{errors.name4.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.services.name5")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.services.services.name5")}
            height="48px" borderRadius={4}
            {...register("name5", {
              required: t("validation.required")
            })}
          />
          {errors.name5 && (
            <FormHelperText color="red.600">{errors.name5.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.services.name6")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.services.services.name6")}
            height="48px" borderRadius={4}
            {...register("name6", {
              required: t("validation.required")
            })}
          />
          {errors.name6 && (
            <FormHelperText color="red.600">{errors.name6.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.services.services_images")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.services.services.services_images")}
            height="48px" borderRadius={4} pt={2} multiple
            {...register("servicesImages")}
          />
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.services.services_icons")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.services.services.services_icons")}
            height="48px" borderRadius={4} pt={2} multiple
            {...register("servicesIcons")}
          />
        </FormControl>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          textTransform="capitalize"
          _hover={{ bg: "green.600" }}
        >
          {t("general.save")}
        </Button>
      </Stack>
    </form>
  )
}

export default Services