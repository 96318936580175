import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: {},
  error: null,
}

export const getHomeSettings = createAsyncThunk(
  "homeSettings/getHomeSettings",
  async (_, thunkApi) => {
    try {
      const { data } = await axiosInstace.get("/home");
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createMainSettings = createAsyncThunk(
  "homeSettings/createMainSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/home/main", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateMainSettings = createAsyncThunk(
  "homeSettings/updateMainSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put("/home/main", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateBlogSettings = createAsyncThunk(
  "homeSettings/updateBlogSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/home/blog", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createPortofolioSettings = createAsyncThunk(
  "homeSettings/createPortofolioSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/home/portofolio", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updatePortofolioSettings = createAsyncThunk(
  "homeSettings/updatePortofolioSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put("/home/portofolio", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateInfoSettings = createAsyncThunk(
  "homeSettings/updateInfoSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/home/mainInfo", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateVideoSettings = createAsyncThunk(
  "homeSettings/updateVideoSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/home/video", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createAboutSettings = createAsyncThunk(
  "homeSettings/createAboutSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/home/about", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateAboutSettings = createAsyncThunk(
  "homeSettings/updateAboutSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put("/home/about", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createServicesSettings = createAsyncThunk(
  "homeSettings/createServicesSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/home/services", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateServicesSettings = createAsyncThunk(
  "homeSettings/updateServicesSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put("/home/services", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createFooterSettings = createAsyncThunk(
  "homeSettings/createFooterSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/home/footer", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateFooterSettings = createAsyncThunk(
  "homeSettings/updateFooterSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put("/home/footer", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const homeSettingsSlice = createSlice({
  name: "homeSettings",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getHomeSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getHomeSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload.data && payload.data[0]) || {};
        state.error = null;
      })
      .addCase(getHomeSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(createMainSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createMainSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload.data && payload.data) || {};
        state.error = null;
      })
      .addCase(createMainSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateMainSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateMainSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload.data && payload.data) || {};
        state.error = null;
      })
      .addCase(updateMainSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      // blog settings
      .addCase(updateBlogSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateBlogSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload.data && payload.data) || {};
        state.error = null;
      })
      .addCase(updateBlogSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      // info settings
      .addCase(updateInfoSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateInfoSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload.data && payload.data) || {};
        state.error = null;
      })
      .addCase(updateInfoSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      // portofolio settings
      .addCase(createPortofolioSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createPortofolioSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload.data && payload.data) || {};
        state.error = null;
      })
      .addCase(createPortofolioSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updatePortofolioSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePortofolioSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload.data && payload.data) || {};
        state.error = null;
      })
      .addCase(updatePortofolioSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      // video settings
      .addCase(updateVideoSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateVideoSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload.data && payload.data) || {};
        state.error = null;
      })
      .addCase(updateVideoSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      // about settings
      .addCase(createAboutSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createAboutSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload.data && payload.data) || {};
        state.error = null;
      })
      .addCase(createAboutSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateAboutSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateAboutSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload.data && payload.data) || {};
        state.error = null;
      })
      .addCase(updateAboutSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      // services settings
      .addCase(createServicesSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createServicesSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload.data && payload.data) || {};
        state.error = null;
      })
      .addCase(createServicesSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateServicesSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateServicesSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload.data && payload.data) || {};
        state.error = null;
      })
      .addCase(updateServicesSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      // footer settings
      .addCase(createFooterSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createFooterSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload.data && payload.data) || {};
        state.error = null;
      })
      .addCase(createFooterSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateFooterSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateFooterSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload.data && payload.data) || {};
        state.error = null;
      })
      .addCase(updateFooterSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
  }
});

export default homeSettingsSlice.reducer;