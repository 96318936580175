import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  itemsCount: 0,
  error: null,
}

export const getBlogs = createAsyncThunk(
  "blogs/getBlogs",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const { data } = await axiosInstace.get(`/blogs?page=${page}&size=${size}&query=${query}`,);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createBlog = createAsyncThunk(
  "blogs/createBlog",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("blogs", args);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateBlog = createAsyncThunk(
  "blogs/updateBlog",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put(`/blogs/${args._id}`, args.values);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteBlog = createAsyncThunk(
  "blogs/deleteBlog",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.delete(`/blogs/${args._id}`);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
)

const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getBlogs.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getBlogs.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = payload.data.data;
        state.pages = payload.data.pages;
        state.itemsCount = payload.data.itemsCount;
      })
      .addCase(getBlogs.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(createBlog.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createBlog.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data.push(payload.data);
        state.itemsCount++;
      })
      .addCase(createBlog.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateBlog.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateBlog.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.isLoading = false;
        state.error = null;
        const indexAt = state.data?.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
      })
      .addCase(updateBlog.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(deleteBlog.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteBlog.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = state.data.filter(el => el._id !== payload.data._id);
      })
      .addCase(deleteBlog.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
  }
});

export default blogsSlice.reducer;