import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Alert,
  AlertIcon,
  Text,
  useToast,
} from "@chakra-ui/react";
import { updateBlogSettings } from "src/store/settings/home/homeSettingsSlice";

const Blog = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const dispatch = useDispatch();
  const homeSettings = useSelector(state => state.homeSettings);
  const blogSettings = homeSettings.data?.blogSection || {};

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: blogSettings
  });

  return (
    <form onSubmit={handleSubmit(values => {
      delete values._id;
      dispatch(updateBlogSettings(values))
        .unwrap()
        .then(_ => {
          toast({ description: "settings updated" });
        })
        .catch(e => {
          window.scrollTo(0, 0);
        })
    })}>
      <Stack spacing={6}>
        {homeSettings.error && (
          <Alert status="error" variant="left-accent">
            <AlertIcon />
            <Text>{homeSettings.error || "-"}</Text>
          </Alert>
        )}

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.blog.title")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.blog.title")}
            height="48px" borderRadius={4}
            {...register("title", {
              required: t("validation.required")
            })}
          />
          {errors.title && (
            <FormHelperText color="red.600">{errors.title.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.blog.background_title")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.blog.background_title")}
            height="48px" borderRadius={4}
            {...register("backgroundTitle", {
              required: t("validation.required")
            })}
          />
          {errors.backgroundTitle && (
            <FormHelperText color="red.600">{errors.backgroundTitle.message}</FormHelperText>
          )}
        </FormControl>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          textTransform="capitalize"
          _hover={{ bg: "green.600" }}
        >
          {t("general.save")}
        </Button>
      </Stack>
    </form>
  )
}

export default Blog