import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Alert,
  AlertIcon,
  Text,
  useToast,
} from "@chakra-ui/react";
import { updateInfoSettings } from "src/store/settings/home/homeSettingsSlice";

const Info = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const dispatch = useDispatch();
  const homeSettings = useSelector(state => state.homeSettings);
  const infoSettings = homeSettings.data?.mainInfo || {};

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: infoSettings
  });

  return (
    <form onSubmit={handleSubmit(values => {
      delete values?._id;
      delete values?.socialMedia?._id;
      dispatch(updateInfoSettings(values))
        .unwrap()
        .then(_ => {
          toast({ description: "settings updated" });
        })
        .catch(e => {
          window.scrollTo(0, 0);
        })
    })}>
      <Stack spacing={6}>
        {homeSettings.error && (
          <Alert status="error" variant="left-accent">
            <AlertIcon />
            <Text>{homeSettings.error || "-"}</Text>
          </Alert>
        )}

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.info.address")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.info.address")}
            height="48px" borderRadius={4}
            {...register("address", {
              required: t("validation.required")
            })}
          />
          {errors.address && (
            <FormHelperText color="red.600">{errors.address.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.info.mail")}
          </FormLabel>
          <Input
            type="email" placeholder={t("pages.settings.home.info.mail")}
            height="48px" borderRadius={4}
            {...register("mail", {
              required: t("validation.required")
            })}
          />
          {errors.mail && (
            <FormHelperText color="red.600">{errors.mail.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.info.whatsapp")}
          </FormLabel>
          <Input
            type="tel" placeholder={t("pages.settings.home.info.whatsapp")}
            height="48px" borderRadius={4}
            {...register("whatsapp", {
              required: t("validation.required")
            })}
          />
          {errors.whatsapp && (
            <FormHelperText color="red.600">{errors.whatsapp.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.info.phone")}
          </FormLabel>
          <Input
            type="tel" placeholder={t("pages.settings.home.info.phone")}
            height="48px" borderRadius={4}
            {...register("phone", {
              required: t("validation.required")
            })}
          />
          {errors.phone && (
            <FormHelperText color="red.600">{errors.phone.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.info.facebook")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.info.facebook")}
            height="48px" borderRadius={4}
            {...register("socialMedia.facebook", {
              required: t("validation.required")
            })}
          />
          {errors.socialMedia?.facebook && (
            <FormHelperText color="red.600">{errors.socialMedia.facebook.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.info.tiktok")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.info.tiktok")}
            height="48px" borderRadius={4}
            {...register("socialMedia.tiktok", {
              required: t("validation.required")
            })}
          />
          {errors.socialMedia?.tiktok && (
            <FormHelperText color="red.600">{errors.socialMedia.tiktok.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.info.youtube")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.info.youtube")}
            height="48px" borderRadius={4}
            {...register("socialMedia.youtube", {
              required: t("validation.required")
            })}
          />
          {errors.socialMedia?.youtube && (
            <FormHelperText color="red.600">{errors.socialMedia.youtube.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.info.instagram")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.info.instagram")}
            height="48px" borderRadius={4}
            {...register("socialMedia.instagram", {
              required: t("validation.required")
            })}
          />
          {errors.socialMedia?.instagram && (
            <FormHelperText color="red.600">{errors.socialMedia.instagram.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.info.pinterest")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.info.pinterest")}
            height="48px" borderRadius={4}
            {...register("socialMedia.pinterest", {
              required: t("validation.required")
            })}
          />
          {errors.socialMedia?.pinterest && (
            <FormHelperText color="red.600">{errors.socialMedia.pinterest.message}</FormHelperText>
          )}
        </FormControl>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          textTransform="capitalize"
          _hover={{ bg: "green.600" }}
        >
          {t("general.save")}
        </Button>
      </Stack>
    </form>
  )
}

export default Info