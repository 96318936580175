import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal, ModalOverlay,
  ModalContent, ModalHeader,
  ModalFooter, ModalBody,
  Button, FormControl,
  FormLabel, Input,
  Flex, Text,
  Alert,
  AlertIcon,
  Stack,
  useToast,
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';
import Search from "src/components/shared/search/Search";

import { updateSalary } from '../../../../store/salaries/salariesSlice';

import theme from '../../../global/theme';
import { getUsers } from "src/store/users/usersSlice";

const UpdateModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const dispatch = useDispatch();
  const salaries = useSelector(state => state.salaries);
  const users = useSelector(state => state.users);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: data
  });

  watch(["user"]);

  useEffect(() => {
    dispatch(getUsers({ page: 1, size: 10 }));
  }, [dispatch]);

  return (
    <Modal isOpen={true} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form onSubmit={handleSubmit(values => {
          delete values.total;
          values.user = values.user?._id;
          delete values._id;
          delete values.createdAt;
          delete values.updatedAt;
          delete values.__v;
          dispatch(updateSalary({ values, _id: data._id }))
            .unwrap()
            .then(res => {
              toast({ description: "item updated" });
              onClose();
            }).catch(e => {
              console.log(e);
            })
        })}>
          <ModalHeader textAlign="center" color="orange" textTransform="uppercase" fontSize={22}>
            {t('general.update')}
          </ModalHeader>
          <ModalBody>

            {salaries.error && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                <Text>{salaries.error}</Text>
              </Alert>
            )}

            <Stack spacing={6}>
              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.salaries.user')}
                </FormLabel>
                <Search
                  page={1}
                  itemsCount={users.itemsCount}
                  data={users.data}
                  allowPagination={false}
                  allowSearch={false}
                  prop="name"
                  currentElement={getValues("user")}
                  handleSelected={(item) => { setValue("user", item) }}
                />
              </FormControl>

              {getValues("user") && (
                <>
                  <FormControl>
                    <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                      {t('pages.salaries.date')}
                    </FormLabel>
                    <Input type="date" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                      placeholder={t('pages.salaries.date')} _placeholder={{ textTransform: 'capitalize' }}
                      {...register("date", {
                        required: `${t('validation.required')}`
                      })}
                    />
                    {errors.date?.message &&
                      <Text color="red.600" marginTop={2}>{errors.date.message}</Text>}
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                      {t('pages.salaries.amount')}
                    </FormLabel>
                    <Input type="number" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                      placeholder={t('pages.salaries.amount')} _placeholder={{ textTransform: 'capitalize' }}
                      {...register("amount", {
                        required: `${t('validation.required')}`
                      })}
                    />
                    {errors.amount?.message &&
                      <Text color="red.600" marginTop={2}>{errors.amount.message}</Text>}
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                      {t('pages.salaries.rewards')}
                    </FormLabel>
                    <Input type="number" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                      placeholder={t('pages.salaries.rewards')} _placeholder={{ textTransform: 'capitalize' }}
                      {...register("rewards", {
                        required: `${t('validation.required')}`
                      })}
                    />
                    {errors.rewards?.message &&
                      <Text color="red.600" marginTop={2}>{errors.rewards.message}</Text>}
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                      {t('pages.salaries.deduction')}
                    </FormLabel>
                    <Input type="number" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                      placeholder={t('pages.salaries.deduction')} _placeholder={{ textTransform: 'capitalize' }}
                      {...register("deduction", {
                        required: `${t('validation.required')}`
                      })}
                    />
                    {errors.deduction?.message &&
                      <Text color="red.600" marginTop={2}>{errors.deduction.message}</Text>}
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                      {t('pages.salaries.bounce')}
                    </FormLabel>
                    <Input type="number" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                      placeholder={t('pages.salaries.bounce')} _placeholder={{ textTransform: 'capitalize' }}
                      {...register("bounce", {
                        required: `${t('validation.required')}`
                      })}
                    />
                    {errors.bounce?.message &&
                      <Text color="red.600" marginTop={2}>{errors.bounce.message}</Text>}
                  </FormControl>
                </>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white" bg="green" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                isLoading={salaries.isLoading}
                _hover={{ background: 'green' }}
              >
                {t('general.save')}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white" bg="red.600" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                marginInlineStart={4}
                isLoading={salaries.isLoading}
                _hover={{ background: 'red.600' }}
              >
                {t('general.close')}
              </Button>
            </Flex>
          </ModalFooter>
        </form >
      </ModalContent >
    </Modal >
  )
}

export default UpdateModal