import { useTranslation } from 'react-i18next';
import { Text, Box } from '@chakra-ui/react';
import Statistics from "./components/Statistics";
import theme from '../../global/theme';

const Reports = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box paddingBlock={8} justifyContent="space-between" alignItems="center">
        <Text
          fontWeight="bold" textTransform="capitalize"
          fontSize={16} color={theme.dark}
        >
          {t('pages.reports.reports')}
        </Text>
      </Box>

      <Statistics />
    </>
  )
}

export default Reports