import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: {},
  error: null,
}

export const getServicesSettings = createAsyncThunk(
  "servicesSettings/getServicesSettings",
  async (_, thunkApi) => {
    try {
      const { data } = await axiosInstace.get("/services");
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createMainSettings = createAsyncThunk(
  "servicesSettings/createMainSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/services/main", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateMainSettings = createAsyncThunk(
  "servicesSettings/updateMainSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put("/services/main", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateServicesSettings = createAsyncThunk(
  "servicesSettings/updateServicesSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/services/services", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createOfferSettings = createAsyncThunk(
  "servicesSettings/createOfferSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/services/offer", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateOfferSettings = createAsyncThunk(
  "servicesSettings/updateOfferSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put("/services/offer", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const servicesSettingsSlice = createSlice({
  name: "servicesSettings",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getServicesSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getServicesSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(getServicesSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(createMainSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createMainSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(createMainSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateMainSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateMainSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(updateMainSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateServicesSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateServicesSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(updateServicesSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(createOfferSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createOfferSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(createOfferSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateOfferSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateOfferSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(updateOfferSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
  }
});

export default servicesSettingsSlice.reducer;