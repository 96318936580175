import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Alert,
  AlertIcon,
  Text,
  useToast,
  Image,
  Spinner,
} from "@chakra-ui/react";
import { createDreamShotSettings, updateDreamShotSettings } from "src/store/settings/dreamShot/dreamShotSettingsSlice";

const Main = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const dispatch = useDispatch();
  const dreamShot = useSelector(state => state.dreamShot);
  const dreamShotSettings = dreamShot?.data || {};

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
    handleSubmit
  } = useForm({
    defaultValues: dreamShotSettings
  });

  watch(["background", "icon"]);

  return (
    <form onSubmit={handleSubmit(values => {
      const formData = new FormData();
      formData.set("title", values.title);
      formData.set("description", values.description);
      formData.set("background", typeof (values.background) === "string" ? values.background : values.background[0]);
      formData.set("icon", typeof (values.icon) === "string" ? values.icon : values.icon[0]);
      dispatch(Object.keys(dreamShotSettings).length > 0 ? updateDreamShotSettings(formData) : createDreamShotSettings(formData))
        .unwrap()
        .then(_ => {
          toast({ description: "settings updated" });
        })
        .catch(e => {
          window.scrollTo(0, 0);
        })
    })}>
      {dreamShot.isLoading ? (
        <Spinner />
      ) : (
        <Stack spacing={6}>
          {dreamShot.error && (
            <Alert status="error" variant="left-accent">
              <AlertIcon />
              <Text>{dreamShot.error || "-"}</Text>
            </Alert>
          )}

          <FormControl>
            <FormLabel textTransform="capitalize">
              {t("pages.settings.dream_shot.title")}
            </FormLabel>
            <Input
              type="text" placeholder={t("pages.settings.dream_shot.title")}
              height="48px" borderRadius={4}
              {...register("title", {
                required: t("validation.required")
              })}
            />
            {errors.title && (
              <FormHelperText color="red.600">{errors.title.message}</FormHelperText>
            )}
          </FormControl>

          <FormControl>
            <FormLabel textTransform="capitalize">
              {t("pages.settings.dream_shot.description")}
            </FormLabel>
            <Input
              type="text" placeholder={t("pages.settings.dream_shot.description")}
              height="48px" borderRadius={4}
              {...register("description", {
                required: t("validation.required")
              })}
            />
            {errors.description && (
              <FormHelperText color="red.600">{errors.description.message}</FormHelperText>
            )}
          </FormControl>

          <FormControl>
            <FormLabel textTransform="capitalize">
              {t("pages.settings.dream_shot.background")}
            </FormLabel>
            <Input
              type="file" placeholder={t("pages.settings.dream_shot.background")}
              height="48px" borderRadius={4} pt={2}
              {...register("background")}
            />
            {typeof (getValues("background")) === "string" && getValues("background") && (
              <>
                <Image mt={2} src={getValues("background")} height="150px" objectFit="contain" />
                <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("background", null)}>
                  {t("general.delete")}
                </Button>
              </>
            )}
          </FormControl>

          <FormControl>
            <FormLabel textTransform="capitalize">
              {t("pages.settings.dream_shot.icon")}
            </FormLabel>
            <Input
              type="file" placeholder={t("pages.settings.dream_shot.icon")}
              height="48px" borderRadius={4} pt={2}
              {...register("icon")}
            />
            {typeof (getValues("icon")) === "string" && getValues("icon") && (
              <>
                <Image mt={2} src={getValues("icon")} height="150px" objectFit="contain" />
                <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("icon", null)}>
                  {t("general.delete")}
                </Button>
              </>
            )}
          </FormControl>

          <Button
            type="submit"
            bg="green.600"
            color="white"
            textTransform="capitalize"
            _hover={{ bg: "green.600" }}
          >
            {t("general.save")}
          </Button>
        </Stack>
      )}
    </form>
  )
}

export default Main