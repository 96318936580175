import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  itemsCount: 0,
  error: null,
}

export const getCodes = createAsyncThunk(
  "codes/getCodes",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const { data } = await axiosInstace.get(`/promoCodes?page=${page}&size=${size}&query=${query}`,);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createCode = createAsyncThunk(
  "codes/createCode",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/promoCodes", args);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateCode = createAsyncThunk(
  "codes/updateCode",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put(`/promoCodes/${args._id}`, args.values);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteCode = createAsyncThunk(
  "codes/deleteCode",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.delete(`/promoCodes/${args._id}`);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
)

const codesSlice = createSlice({
  name: "codes",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getCodes.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCodes.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = payload.data.data;
        state.pages = payload.data.pages;
        state.itemsCount = payload.data.itemsCount;
      })
      .addCase(getCodes.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(createCode.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCode.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data.push(payload.data);
        state.itemsCount++;
      })
      .addCase(createCode.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateCode.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCode.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.isLoading = false;
        state.error = null;
        const indexAt = state.data?.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
      })
      .addCase(updateCode.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(deleteCode.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteCode.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = state.data.filter(el => el._id !== payload.data._id);
      })
      .addCase(deleteCode.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
  }
});

export default codesSlice.reducer;