import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Flex, Button, Text, InputGroup, Input, InputRightElement, SimpleGrid, Alert, AlertIcon, Select } from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';

import Pagination from 'src/components/shared/pagination/Pagination';
import Breadcrumbs from 'src/components/shared/breadcrumbs/Breadcrumbs';
import ServicesTable from './Table';
import CreateModal from './actions/CreateModal';
import UpdateModal from './actions/UpdateModal';
import DeleteModal from './actions/DeleteModal';

import { getServices } from 'src/store/services/servicesSlice';

import { Wrapper } from './Styles';

import theme from '../../global/theme';
import Filter from "./actions/Filter";

const Services = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const services = useSelector(state => state.services);

  const defaultFilter = {};
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState(defaultFilter);

  const [showCreateService, setShowCreateService] = useState(false);
  const [showUpdateService, setShowUpdateService] = useState(false);
  const [showDeleteService, setShowDeleteService] = useState(false);

  useEffect(() => {
    dispatch(getServices({ page, query, filter }));
  }, [dispatch, page, query, filter]);


  return (
    <Wrapper>
      <Breadcrumbs
        currentPage={t('pages.services.services')}
        pages={[{ name: `${t('pages.dashboard.dashboard')}`, path: '/' }]}
      />

      {services.error && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          <Text>{services.error}</Text>
        </Alert>
      )}

      <SimpleGrid columns={{ sm: 1, md: 2 }} justifyContent="space-between">
        <Flex marginBottom={8}>
          <Button bg="red.600" textTransform="uppercase"
            fontWeight="bold" fontSize={18} color="white" _hover={{ bg: 'red.600' }}
            onClick={() => setShowCreateService(true)}
          >
            {t('general.create')} +
          </Button>
          <Filter
            handleFilter={(filter) => {
              setFilter(prev => ({
                ...prev,
                dateFrom: filter.from,
                dateTo: filter.to,
              }))
            }}
            handleClearFilter={() => {
              setFilter(prev => ({
                ...prev,
                dateFrom: "",
                dateTo: "",
              }))
            }}
          />
        </Flex>

        <Flex marginBottom={8} justifyContent="flex-end" gap={4}>
          <InputGroup width="auto">
            <Input auth="auto" type="text" placeholder={t('general.search')}
              bg={theme.light} boxShadow={theme.shadow} color={theme.dark} border="none"
              onChange={(e) => setQuery(e.currentTarget.value)}
            />
            <InputRightElement color={theme.light} children={<FiIcons.FiSearch />} />
          </InputGroup>
          <Select
            width="auto" bg="white" border="none"
            onChange={(e) => setFilter(prev => ({
              ...prev,
              service: e.target.value
            }))}
          >
            <option value="">All</option>
            <option value="studio renting">studio renting</option>
            <option value="neon signs">neon signs</option>
            <option value="photography">photography</option>
            <option value="videography">videography</option>
            <option value="social media">social media</option>
            <option value="web design">web design</option>
          </Select>
        </Flex>
      </SimpleGrid>

      {services.itemsCount > 0 ? (
        <ServicesTable
          data={services.data}
          page={page}
          handleUpdate={(el) => setShowUpdateService(el)}
          handleDelete={(el) => setShowDeleteService(el)}
        />
      ) : (
        <Flex textAlign="center" bg={theme.light} boxShadow={theme.shadow} height={200} alignItems="center"
          justifyContent="center" borderRadius={16}>
          <Text fontSize={18} textTransform="capitalize" color="gray.300" fontWeight="bold">
            {t('pages.services.no_services')}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={services.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showCreateService && <CreateModal onClose={() => setShowCreateService(false)} />}
      {showUpdateService && <UpdateModal data={showUpdateService} onClose={() => setShowUpdateService(false)} />}
      {showDeleteService && <DeleteModal data={showDeleteService} onClose={() => setShowDeleteService(false)} />}
    </Wrapper>
  )
}

export default Services