import axios from "axios";
import Cookies from "js-cookie";

const axiosInstace = axios.create({
  baseURL: "https://api.scalee-agency.com",
  timeout: 20000
});

axiosInstace.interceptors.request.use(config => {
  config.headers['auth-token'] = Cookies.get("token");
  return config;
});

export default axiosInstace;