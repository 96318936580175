import { Routes, Route } from 'react-router-dom';

import ProtectedRoutes from './ProtectedRoutes';

import Login from '../modules/auth/Login';

import Dashboard from '../modules/dashboard/Dashboard';

import SettingsHome from "../modules/settings/home/Home";
import SettingsHomeMain from "../modules/settings/home/tabs/Main";
import SettingsHomeBlog from "../modules/settings/home/tabs/Blog";
import SettingsHomeInfo from "../modules/settings/home/tabs/Info";
import SettingsHomePortofolio from "../modules/settings/home/tabs/Portofolio";
import SettingsHomeVideo from "../modules/settings/home/tabs/Video";
import SettingsHomeAbout from "../modules/settings/home/tabs/About";
import SettingsHomeServices from "../modules/settings/home/tabs/Services";
import SettingsHomeFooter from "../modules/settings/home/tabs/Footer";

import SettingsAbout from "../modules/settings/about/About";
import SettingsAboutMain from "../modules/settings/about/tabs/Main";

import SettingsBlogs from "../modules/settings/blogs/Blogs";
import SettingsBlogsMain from "../modules/settings/blogs/tabs/Main";

import SettingsStudio from "../modules/settings/studio/Studio";
import SettingsStudioMain from "../modules/settings/studio/tabs/Main";

import SettingsContact from "../modules/settings/contact/Contact";
import SettingsContactMain from "../modules/settings/contact/tabs/Main";

import SettingsSale from "../modules/settings/sale/Sale";
import SettingsSaleMain from "../modules/settings/sale/tabs/Main";

import SettingsServices from "../modules/settings/services/Services";
import SettingsServicesMain from "../modules/settings/services/tabs/Main";
import SettingsServicesServices from "../modules/settings/services/tabs/Services";
import SettingsServicesOffer from "../modules/settings/services/tabs/Offer";
import SettingsServicesAllServices from "../modules/settings/services/tabs/AllServices";


import SettingsDreamShot from "../modules/settings/dreamShot/DreamShot";
import SettingsDreamShotMain from "../modules/settings/dreamShot/tabs/Main";

import Users from "../modules/users/Users";
import Blogs from "../modules/blogs/Blogs";
import Reviews from "../modules/reviews/Reviews";
import Clients from "../modules/clients/Clients";
import Features from "../modules/features/Features";
import Plans from "../modules/plans/Plans";
import Portofolio from "../modules/portofolio/Portofolio";
import Contacts from "../modules/contacts/Contacts";
import Reservations from "../modules/reservations/Reservations";
import Salaries from "../modules/salaries/Salaries";
import Expenses from "../modules/expenses/Expenses";
import Services from "../modules/services/Services";
import Reports from "../modules/reports/Reports";
import Codes from "../modules/codes/Codes";
import Join from "../modules/join/Join";

const Navigations = () => {
  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route element={<ProtectedRoutes />}>
        <Route path='' element={<Dashboard />} />
        <Route path="settings/home" element={<SettingsHome />}>
          <Route path='' element={<SettingsHomeMain />} />
          <Route path='blog' element={<SettingsHomeBlog />} />
          <Route path='info' element={<SettingsHomeInfo />} />
          <Route path='portofolio' element={<SettingsHomePortofolio />} />
          <Route path='video' element={<SettingsHomeVideo />} />
          <Route path='about' element={<SettingsHomeAbout />} />
          <Route path='services' element={<SettingsHomeServices />} />
          <Route path='footer' element={<SettingsHomeFooter />} />
        </Route>
        <Route path="settings/about" element={<SettingsAbout />}>
          <Route path='' element={<SettingsAboutMain />} />
        </Route>
        <Route path="settings/blogs" element={<SettingsBlogs />}>
          <Route path='' element={<SettingsBlogsMain />} />
        </Route>
        <Route path="settings/studio_renting" element={<SettingsStudio />}>
          <Route path='' element={<SettingsStudioMain />} />
        </Route>
        <Route path="settings/contact_us" element={<SettingsContact />}>
          <Route path='' element={<SettingsContactMain />} />
        </Route>
        <Route path="settings/sale" element={<SettingsSale />}>
          <Route path='' element={<SettingsSaleMain />} />
        </Route>
        <Route path="settings/services" element={<SettingsServices />}>
          <Route path='' element={<SettingsServicesMain />} />
          <Route path='services' element={<SettingsServicesServices />} />
          <Route path='offer' element={<SettingsServicesOffer />} />
          <Route path='all_services' element={<SettingsServicesAllServices />} />
        </Route>
        <Route path="settings/dream_shot" element={<SettingsDreamShot />}>
          <Route path='' element={<SettingsDreamShotMain />} />
        </Route>
        <Route path="users" element={<Users />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="reviews" element={<Reviews />} />
        <Route path="clients" element={<Clients />} />
        <Route path="features" element={<Features />} />
        <Route path="plans" element={<Plans />} />
        <Route path="portofolio" element={<Portofolio />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="join_us" element={<Join />} />
        <Route path="reservations" element={<Reservations />} />
        <Route path="salaries" element={<Salaries />} />
        <Route path="general_expenses" element={<Expenses />} />
        <Route path="services" element={<Services />} />
        <Route path="reports" element={<Reports />} />
        <Route path="codes" element={<Codes />} />
      </Route>
    </Routes>
  )
}

export default Navigations