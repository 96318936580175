import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  itemsCount: 0,
  error: null,
}

export const getPortofolio = createAsyncThunk(
  "portofolio/getPortofolio",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const { data } = await axiosInstace.get(`/portofolio?page=${page}&size=${size}&query=${query}`,);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createPortofolio = createAsyncThunk(
  "portofolio/createPortofolio",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("portofolio", args);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updatePortofolio = createAsyncThunk(
  "portofolio/updatePortofolio",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put(`/portofolio/${args._id}`, args.values);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deletePortofolio = createAsyncThunk(
  "portofolio/deletePortofolio",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.delete(`/portofolio/${args._id}`);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
)

const portofolioSlice = createSlice({
  name: "portofolio",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getPortofolio.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPortofolio.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = payload.data.data;
        state.pages = payload.data.pages;
        state.itemsCount = payload.data.itemsCount;
      })
      .addCase(getPortofolio.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(createPortofolio.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createPortofolio.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data.push(payload.data);
        state.itemsCount++;
      })
      .addCase(createPortofolio.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updatePortofolio.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePortofolio.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.isLoading = false;
        state.error = null;
        const indexAt = state.data?.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
      })
      .addCase(updatePortofolio.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(deletePortofolio.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deletePortofolio.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = state.data.filter(el => el._id !== payload.data._id);
      })
      .addCase(deletePortofolio.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
  }
});

export default portofolioSlice.reducer;