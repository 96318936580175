import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  income: {},
  outcome: {},
  error: null,
}

export const getIncome = createAsyncThunk(
  "reports/getIncome",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.get("/reports/income",);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getOutcome = createAsyncThunk(
  "reports/getOutcome",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.get("/reports/outcome",);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getIncome.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getIncome.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.income = payload.data["studio renting"];
      })
      .addCase(getIncome.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(getOutcome.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getOutcome.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.outcome = payload.data.salaries;
      })
      .addCase(getOutcome.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
  }
});

export default reportsSlice.reducer;