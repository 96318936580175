import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Alert,
  AlertIcon,
  Text,
  useToast,
  Image,
} from "@chakra-ui/react";
import { createMainSettings, updateMainSettings } from "src/store/settings/home/homeSettingsSlice";
import React from "react";

const Main = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const dispatch = useDispatch();
  const homeSettings = useSelector(state => state.homeSettings);
  const mainSettings = homeSettings.data?.mainSection || null;

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    watch,
    setValue
  } = useForm({
    defaultValues: mainSettings || {}
  });

  watch(["logo", "headerGalleryImages", "backgroundImages"]);

  return (
    <form onSubmit={handleSubmit(values => {
      const formData = new FormData();

      formData.set("title", values.title);
      formData.set("subtitle", values.subtitle);
      formData.set("button", values.button);

      if (Array.isArray(values.headerGalleryImages)) {
        for (let i = 0; i < values.headerGalleryImages.length; i++) {
          if (values.headerGalleryImages[i]) {
            formData.append("headerGalleryImages[]", values.headerGalleryImages[i]);
          }
        }
      } else {
        for (let i = 0; i < values.headerGalleryImages.length; i++) {
          if (values.headerGalleryImages[i]) {
            formData.append("headerGalleryImages", values.headerGalleryImages[i]);
          }
        }
      }

      if (Array.isArray(values.backgroundImages)) {
        for (let i = 0; i < values.backgroundImages.length; i++) {
          if (values.backgroundImages[i]) {
            formData.append("backgroundImages[]", values.backgroundImages[i]);
          }
        }
      } else {
        for (let i = 0; i < values.backgroundImages.length; i++) {
          if (values.backgroundImages[i]) {
            formData.append("backgroundImages", values.backgroundImages[i]);
          }
        }
      }

      if (typeof (values.logo) === "string") {
        formData.set("logo", values.logo);
      } else {
        formData.set("logo", values.logo[0]);
      }

      dispatch(mainSettings ? updateMainSettings(formData) : createMainSettings(formData))
        .unwrap()
        .then(_ => {
          toast({ description: "settings updated" });
        })
        .catch(e => {
          window.scrollTo(0, 0);
        })
    })}>
      <Stack spacing={6}>
        {homeSettings.error && (
          <Alert status="error" variant="left-accent">
            <AlertIcon />
            <Text>{homeSettings.error || "-"}</Text>
          </Alert>
        )}

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.main.title")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.main.title")}
            height="48px" borderRadius={4}
            {...register("title", {
              required: t("validation.required")
            })}
          />
          {errors.title && (
            <FormHelperText color="red.600">{errors.title.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.main.subtitle")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.main.subtitle")}
            height="48px" borderRadius={4}
            {...register("subtitle", {
              required: t("validation.required")
            })}
          />
          {errors.subtitle && (
            <FormHelperText color="red.600">{errors.subtitle.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.main.logo")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.home.main.logo")}
            height="48px" borderRadius={4} pt={2}
            {...register("logo")}
          />
          {typeof (getValues("logo")) === "string" && getValues("logo") && (
            <>
              <Image mt={2} src={getValues("logo")} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("logo", null)}>
                {t("general.delete")}
              </Button>
            </>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.main.gallery_images")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.home.main.gallery_images")}
            height="48px" borderRadius={4} pt={2} multiple
            {...register("headerGalleryImages")}
          />
          {Array.isArray(getValues("headerGalleryImages")) && getValues("headerGalleryImages").map((el, idx) => el ? (
            <React.Fragment key={idx}>
              <Image mt={2} src={el} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue(`headerGalleryImages.${idx}`, null)}>
                {t("general.delete")}
              </Button>
            </React.Fragment>
          ) : null)}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.main.background_images")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.home.main.background_images")}
            height="48px" borderRadius={4} pt={2} multiple
            {...register("backgroundImages")}
          />
          {Array.isArray(getValues("backgroundImages")) && getValues("backgroundImages").map((el, idx) => el ? (
            <React.Fragment key={idx}>
              <Image mt={2} src={el} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue(`backgroundImages.${idx}`, null)}>
                {t("general.delete")}
              </Button>
            </React.Fragment>
          ) : null)}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.main.button")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.main.button")}
            height="48px" borderRadius={4}
            {...register("button", {
              required: t("validation.required")
            })}
          />
          {errors.button && (
            <FormHelperText color="red.600">{errors.button.message}</FormHelperText>
          )}
        </FormControl>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          textTransform="capitalize"
          _hover={{ bg: "green.600" }}
        >
          {t("general.save")}
        </Button>
      </Stack>
    </form>
  )
}

export default Main