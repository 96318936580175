import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Alert,
  AlertIcon,
  Text,
  useToast,
  Image,
} from "@chakra-ui/react";
import { createStudioSettings, updateStudioSettings } from "src/store/settings/studio/studioSettingsSlice";
import React from "react";

const Main = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const dispatch = useDispatch();
  const studioSettings = useSelector(state => state.studioSettings);

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
    handleSubmit
  } = useForm({
    defaultValues: studioSettings.data
  });

  watch(["background", "rentingSectionBackground", "images"]);

  return (
    <form onSubmit={handleSubmit(values => {
      const formData = new FormData();

      formData.set("title", values.title);
      formData.set("background", typeof (values.background) === "string" ? values.background : values.background[0]);
      formData.set("rentingSectionBackground", typeof (values.rentingSectionBackground) === "string" ? values.rentingSectionBackground : values.rentingSectionBackground[0]);

      if (Array.isArray(values.images)) {
        for (let i = 0; i < values.images.length; i++) {
          if (values.images[i]) {
            formData.append("images[]", values.images[i]);
          }
        }
      } else {
        for (let i = 0; i < values.images.length; i++) {
          if (values.images[i]) {
            formData.append("images", values.images[i]);
          }
        }
      }


      formData.set("rentingSection[title]", values.rentingSection.title);
      formData.set("rentingSection[subtitle]", values.rentingSection.subtitle);
      formData.set("rentingSection[description]", values.rentingSection.description);

      formData.append("rentingSection[features][0]", values.rentingSection.feature1);
      formData.append("rentingSection[features][1]", values.rentingSection.feature2);

      dispatch(studioSettings ? updateStudioSettings(formData) : createStudioSettings(formData))
        .unwrap()
        .then(_ => {
          toast({ description: "settings updated" });
        })
        .catch(e => {
          window.scrollTo(0, 0);
        })
    })}>
      <Stack spacing={6}>
        {studioSettings.error && (
          <Alert status="error" variant="left-accent">
            <AlertIcon />
            <Text>{studioSettings.error || "-"}</Text>
          </Alert>
        )}

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.studio.main.title")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.studio.main.title")}
            height="48px" borderRadius={4}
            {...register("title", {
              required: t("validation.required")
            })}
          />
          {errors.title && (
            <FormHelperText color="red.600">{errors.title.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.studio.main.background")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.studio.main.background")}
            height="48px" borderRadius={4} pt={2}
            {...register("background")}
          />
          {typeof (getValues("background")) === "string" && getValues("background") && (
            <>
              <Image mt={2} src={getValues("background")} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("background", null)}>
                {t("general.delete")}
              </Button>
            </>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.studio.main.renting_section_background")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.studio.main.renting_section_background")}
            height="48px" borderRadius={4} pt={2}
            {...register("rentingSectionBackground")}
          />
          {typeof (getValues("rentingSectionBackground")) === "string" && getValues("rentingSectionBackground") && (
            <>
              <Image mt={2} src={getValues("rentingSectionBackground")} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("rentingSectionBackground", null)}>
                {t("general.delete")}
              </Button>
            </>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.studio.main.images")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.studio.main.images")}
            height="48px" borderRadius={4} pt={2} multiple
            {...register("images")}
          />
          {Array.isArray(getValues("images")) && getValues("images").map((el, idx) => el ? (
            <React.Fragment key={idx}>
              <Image mt={2} src={el} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue(`images.${idx}`, null)}>
                {t("general.delete")}
              </Button>
            </React.Fragment>
          ) : null)}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.studio.main.renting_section_title")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.studio.main.renting_section_title")}
            height="48px" borderRadius={4}
            {...register("rentingSection.title", {
              required: t("validation.required")
            })}
          />
          {errors.rentingSection?.title && (
            <FormHelperText color="red.600">{errors.rentingSection.title.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.studio.main.renting_section_subtitle")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.studio.main.renting_section_subtitle")}
            height="48px" borderRadius={4}
            {...register("rentingSection.subtitle", {
              required: t("validation.required")
            })}
          />
          {errors.rentingSection?.subtitle && (
            <FormHelperText color="red.600">{errors.rentingSection.subtitle.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.studio.main.renting_section_description")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.studio.main.renting_section_description")}
            height="48px" borderRadius={4}
            {...register("rentingSection.description", {
              required: t("validation.required")
            })}
          />
          {errors.rentingSection?.description && (
            <FormHelperText color="red.600">{errors.rentingSection.description.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.studio.main.renting_section_feature1")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.studio.main.renting_section_feature1")}
            height="48px" borderRadius={4}
            {...register("rentingSection.feature1", {
              required: t("validation.required")
            })}
          />
          {errors.rentingSection?.feature1 && (
            <FormHelperText color="red.600">{errors.rentingSection.feature1.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.studio.main.renting_section_feature2")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.studio.main.renting_section_feature2")}
            height="48px" borderRadius={4}
            {...register("rentingSection.feature2", {
              required: t("validation.required")
            })}
          />
          {errors.rentingSection?.feature2 && (
            <FormHelperText color="red.600">{errors.rentingSection.feature2.message}</FormHelperText>
          )}
        </FormControl>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          textTransform="capitalize"
          _hover={{ bg: "green.600" }}
        >
          {t("general.save")}
        </Button>
      </Stack>
    </form>
  )
}

export default Main