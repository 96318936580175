import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import * as FiIcons from 'react-icons/fi';
import { SimpleGrid, Box, Text, Flex, Divider, } from '@chakra-ui/react';
import theme from '../../../global/theme';
import { getIncome, getOutcome } from "src/store/reports/reportsSlice";

const Statistics = () => {
  const dispatch = useDispatch();
  const reports = useSelector(state => state.reports);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getIncome());
    dispatch(getOutcome());
  }, [dispatch]);

  console.log(reports);

  return (
    <>
      <Text fontSize="1.2rem" fontWeight="500" mb={2} textTransform="capitalize">
        {t("pages.reports.income")}
      </Text>
      <SimpleGrid columns={{ sm: 1, lg: 2 }} spacing={6}>
        <Flex boxShadow={theme.shadow} bg={theme.light} padding={6} alignItems="center" justifyContent="space-between">
          <Flex justifyContent="space-between">
            <Box color="#1C3FAA">
              <FiIcons.FiDollarSign size={40} />
            </Box>
          </Flex>
          <Box>
            <Text
              fontWeight="bold" fontSize={30} mt={4} color={theme.dark}
            >
              {reports.income?.totalAmount ?? "-"}
            </Text>
            <Text
              fontWeight="bold" fontSize={16} textTransform="capitalize" color={theme.dark}>
              {t('pages.reports.total_amount')}
            </Text>
          </Box>
        </Flex>

        <Flex boxShadow={theme.shadow} bg={theme.light} padding={6} alignItems="center" justifyContent="space-between">
          <Flex justifyContent="space-between">
            <Box color="#F78B00">
              <FiIcons.FiDollarSign size={40} />
            </Box>
          </Flex>
          <Box>
            <Text
              fontWeight="bold" fontSize={30} mt={4} color={theme.dark}
            >
              {reports.income?.totalPaidAmount ?? "-"}
            </Text>
            <Text
              fontWeight="bold" fontSize={16} textTransform="capitalize" color={theme.dark}>
              {t('pages.reports.total_paid_amount')}
            </Text>
          </Box>
        </Flex>
      </SimpleGrid>

      <Divider marginBlock={8} />

      <Text fontSize="1.2rem" fontWeight="500" mb={2} textTransform="capitalize">
        {t("pages.reports.outcome")}
      </Text>
      <SimpleGrid columns={{ sm: 1, lg: 2 }} spacing={6}>
        <Flex boxShadow={theme.shadow} bg={theme.light} padding={6} alignItems="center" justifyContent="space-between">
          <Flex justifyContent="space-between">
            <Box color="#008A00">
              <FiIcons.FiDollarSign size={40} />
            </Box>
          </Flex>
          <Box>
            <Text
              fontWeight="bold" fontSize={30} mt={4} color={theme.dark}
            >
              {reports.outcome?.totalAmount ?? "-"}
            </Text>
            <Text
              fontWeight="bold" fontSize={16} textTransform="capitalize" color={theme.dark}>
              {t('pages.reports.total_amount')}
            </Text>
          </Box>
        </Flex>
      </SimpleGrid>
    </>
  )
}

export default Statistics