import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  SimpleGrid,
  Image,
  Text
} from "@chakra-ui/react";
import ServiceModal from "./actions/ServiceModal";

const AllServices = () => {
  const servicesSettings = useSelector(state => state.servicesSettings);
  const allServices = servicesSettings.data?.servicesSection?.services || [];

  const [serviceModal, setServiceModal] = useState(null);

  return (
    <>
      <SimpleGrid spacing={6} columns={{ base: 1, md: 2, lg: 3 }}>
        {allServices.map(service => (
          service.name === "studio renting" ? null : (
            <Button key={service?._id}
              type="button" height="auto" flexDir="column" gap={3} bg="#eee"
              textAlign="center" p={6} borderRadius={4}
              onClick={() => setServiceModal(service?.name)}
            >
              <Image margin="auto" src={service?.icon} width="50px" height="50px" />
              <Text>{service?.name}</Text>
            </Button>
          )
        ))}
      </SimpleGrid>

      {serviceModal && <ServiceModal service={serviceModal} onClose={() => setServiceModal(null)} />}
    </>
  )
}

export default AllServices