import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Spinner, Link as ChakraLink } from "@chakra-ui/react";
import theme from "src/components/global/theme";
import { getServicesSettings } from "src/store/settings/services/servicesSettingsSlice";
import Breadcrumbs from "src/components/shared/breadcrumbs/Breadcrumbs";
import { Wrapper } from "./Styles";

const Services = () => {
  const { t } = useTranslation();
  const servicesSettings = useSelector(state => state.servicesSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServicesSettings())
  }, [dispatch]);

  const TabEl = ({ title, link }) => (
    <ChakraLink
      as={NavLink} end to={link} display="inline-flex"
      textTransform="capitalize" borderBottom="none"
      paddingInline={6} paddingBlock={3}
      _selected={{ bg: "blue.600", color: "white", borderBottom: "none", outline: "none" }}
    >
      {title}
    </ChakraLink>
  );

  const tabs = [
    { title: t("pages.settings.services.main.main"), link: "" },
    { title: t("pages.settings.services.services.services"), link: "services" },
    { title: t("pages.settings.services.offer.offer"), link: "offer" },
    { title: t("pages.settings.services.all_services.all_services"), link: "all_services" },
  ];


  return servicesSettings.isLoading ? (
    <Flex justifyContent="center" bg="white" paddingInline={2} paddingBlock={4}>
      <Spinner />
    </Flex>
  )
    : (
      <Wrapper>
        <Breadcrumbs
          currentPage={t("pages.settings.settings")}
          pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
        />

        <Box bg="white" boxShadow={theme.shadow} borderRadius={4} overflow="hidden" position="relative">
          <Box borderBottom="1px solid #eee">
            {tabs.map((tab, index) => (
              <TabEl title={tab.title} link={tab.link} key={index} />
            ))}
          </Box>
          <Box p={4}>
            <Outlet />
          </Box>
        </Box >
      </Wrapper>
    )
}

export default Services