import { useTranslation } from "react-i18next";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Alert,
  AlertIcon,
  Text,
  useToast,
  Image,
} from "@chakra-ui/react";
import { createServicesSettings, updateServicesSettings } from "src/store/settings/home/homeSettingsSlice";
import React from "react";

const Services = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const dispatch = useDispatch();
  const homeSettings = useSelector(state => state.homeSettings);
  const servicesSettings = homeSettings.data?.servicesSection || [];

  const {
    register,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
    handleSubmit
  } = useForm({
    defaultValues: {
      servicesSettings
    }
  });

  watch(["servicesSettings"]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "servicesSettings"
  });

  return (
    <form onSubmit={handleSubmit(values => {
      const formData = new FormData();

      for (let i = 0; i < values.servicesSettings.length; i++) {
        let item = values.servicesSettings[i];
        delete item._id;
        for (let prop in item) {
          if (prop === "background") {
            formData.append(`backgroundImages`, typeof (item[prop]) === "string" ? item[prop] : item[prop][0]);
          } else if (prop === "icon") {
            formData.append(`icons`, typeof (item[prop]) === "string" ? item[prop] : item[prop][0]);
          } else {
            formData.append(`${prop}[${i}]`, item[prop]);
            console.log(`${prop}[${i}]`);
          }
        }
      }

      dispatch((servicesSettings && servicesSettings?.length > 0) ? updateServicesSettings(formData) : createServicesSettings(formData))
        .unwrap()
        .then(_ => {
          toast({ description: "settings updated" });
        })
        .catch(e => {
          window.scrollTo(0, 0);
        })
    })}>
      <Stack spacing={6}>
        {homeSettings.error && (
          <Alert status="error" variant="left-accent">
            <AlertIcon />
            <Text>{homeSettings.error || "-"}</Text>
          </Alert>
        )}

        {fields.length < 3 && (
          <Button type="button"
            onClick={() => append({})}
          >
            Add
          </Button>
        )}

        {fields.map((field, idx) => (
          <Stack spacing={6} key={field.id} border="1px solid #eee" p={4}>
            <FormControl>
              <FormLabel textTransform="capitalize">
                {t("pages.settings.home.services.background_image")}
              </FormLabel>
              <Input
                type="file" placeholder={t("pages.settings.home.services.background_image")}
                height="48px" borderRadius={4} pt={2} multiple
                {...register(`servicesSettings.${idx}.background`)}
              />
              {getValues(`servicesSettings.${idx}`) && typeof (getValues(`servicesSettings.${idx}.background`)) === "string" && (
                <>
                  <Image mt={2} src={getValues(`servicesSettings.${idx}.background`)} height="150px" objectFit="contain" />
                  <Button mt={2} bg="red.600" color="white" type="button"
                    onClick={() => setValue(`servicesSettings.${idx}.background`, null)}
                  >
                    {t("general.delete")}
                  </Button>
                </>
              )}
            </FormControl>

            <FormControl>
              <FormLabel textTransform="capitalize">
                {t("pages.settings.home.services.icon")}
              </FormLabel>
              <Input
                type="file" placeholder={t("pages.settings.home.services.icon")}
                height="48px" borderRadius={4} pt={2} multiple
                {...register(`servicesSettings.${idx}.icon`)}
              />
              {getValues(`servicesSettings.${idx}`) && typeof (getValues(`servicesSettings.${idx}.icon`)) === "string" && (
                <>
                  <Image mt={2} src={getValues(`servicesSettings.${idx}.icon`)} height="150px" objectFit="contain" />
                  <Button mt={2} bg="red.600" color="white" type="button"
                    onClick={() => setValue(`servicesSettings.${idx}.icon`, null)}
                  >
                    {t("general.delete")}
                  </Button>
                </>
              )}
            </FormControl>

            <FormControl>
              <FormLabel textTransform="capitalize">
                {t("pages.settings.home.services.title")}
              </FormLabel>
              <Input
                type="text" placeholder={t("pages.settings.home.services.title")}
                height="48px" borderRadius={4}
                {...register(`servicesSettings.${idx}.title`, {
                  required: t("validation.required")
                })}
              />
              {errors.title && (
                <FormHelperText color="red.600">{errors.title.message}</FormHelperText>
              )}
            </FormControl>

            <FormControl>
              <FormLabel textTransform="capitalize">
                {t("pages.settings.home.services.subtitle")}
              </FormLabel>
              <Input
                type="text" placeholder={t("pages.settings.home.services.subtitle")}
                height="48px" borderRadius={4}
                {...register(`servicesSettings.${idx}.subtitle`, {
                  required: t("validation.required")
                })}
              />
              {errors.subtitle && (
                <FormHelperText color="red.600">{errors.subtitle.message}</FormHelperText>
              )}
            </FormControl>

            <FormControl>
              <FormLabel textTransform="capitalize">
                {t("pages.settings.home.services.description")}
              </FormLabel>
              <Input
                type="text" placeholder={t("pages.settings.home.services.description")}
                height="48px" borderRadius={4}
                {...register(`servicesSettings.${idx}.description`, {
                  required: t("validation.required")
                })}
              />
              {errors.description && (
                <FormHelperText color="red.600">{errors.description.message}</FormHelperText>
              )}
            </FormControl>

            <FormControl>
              <FormLabel textTransform="capitalize">
                {t("pages.settings.home.services.background_title")}
              </FormLabel>
              <Input
                type="text" placeholder={t("pages.settings.home.services.background_title")}
                height="48px" borderRadius={4}
                {...register(`servicesSettings.${idx}.backgroundTitle`, {
                  required: t("validation.required")
                })}
              />
              {errors.backgroundTitle && (
                <FormHelperText color="red.600">{errors.backgroundTitle.message}</FormHelperText>
              )}
            </FormControl>

            <Button
              type="button" onClick={() => remove(idx)}
              bg="red.900" color="white" textTransform="capitalize"
            >
              {t("general.delete")}
            </Button>
          </Stack>
        ))}

        <Button
          type="submit"
          bg="green.600"
          color="white"
          textTransform="capitalize"
          _hover={{ bg: "green.600" }}
        >
          {t("general.save")}
        </Button>
      </Stack>
    </form>
  )
}

export default Services