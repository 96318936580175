import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: {},
  error: null,
}

export const getContactSettings = createAsyncThunk(
  "contactSettings/getContactSettings",
  async (_, thunkApi) => {
    try {
      const { data } = await axiosInstace.get("/contactUs");
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createContactSettings = createAsyncThunk(
  "contactSettings/createContactSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/contactUs", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateContactSettings = createAsyncThunk(
  "contactSettings/updateContactSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put("/contactUs", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const contactSettingsSlice = createSlice({
  name: "contactSettings",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getContactSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getContactSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(getContactSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(createContactSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createContactSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(createContactSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateContactSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateContactSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(updateContactSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
  }
});

export default contactSettingsSlice.reducer;