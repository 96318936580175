import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Alert,
  AlertIcon,
  Text,
  useToast,
  Image,
} from "@chakra-ui/react";
import { createFooterSettings, updateFooterSettings } from "src/store/settings/home/homeSettingsSlice";
import React from "react";

const Footer = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const dispatch = useDispatch();
  const homeSettings = useSelector(state => state.homeSettings);
  const footerSettings = homeSettings.data?.footerSection?.services || null;

  const {
    register,
    formState: { errors },
    watch,
    getValues,
    setValue,
    handleSubmit
  } = useForm({
    defaultValues: {
      icons: Array.isArray(footerSettings) && footerSettings?.map(el => el.icon),
      title1: Array.isArray(footerSettings) && footerSettings[0]?.title,
      title2: Array.isArray(footerSettings) && footerSettings[1]?.title,
      title3: Array.isArray(footerSettings) && footerSettings[2]?.title,
      title4: Array.isArray(footerSettings) && footerSettings[3]?.title,
    }
  });

  watch(["footerImage", "icons"]);

  return (
    <form onSubmit={handleSubmit(values => {
      const formData = new FormData();

      if (Array.isArray(values.icons)) {
        for (let i = 0; i < values.icons.length; i++) {
          if (values.icons[i]) {
            formData.append("icons[]", values.icons[i]);
          }
        }
      } else {
        for (let i = 0; i < values.icons.length; i++) {
          if (values.icons[i]) {
            formData.append("icons", values.icons[i]);
          }
        }
      }

      formData.append("title", values.title1);
      formData.append("title", values.title2);
      formData.append("title", values.title3);
      formData.append("title", values.title4);

      if (values.footerImage) {
        formData.set("footerImage", values.footerImage[0]);
      }

      dispatch((footerSettings && footerSettings?.length > 0) ? updateFooterSettings(formData) : createFooterSettings(formData))
        .unwrap()
        .then(_ => {
          toast({ description: "settings updated" });
        })
        .catch(e => {
          window.scrollTo(0, 0);
        })
    })}>
      <Stack spacing={6}>
        {homeSettings.error && (
          <Alert status="error" variant="left-accent">
            <AlertIcon />
            <Text>{homeSettings.error || "-"}</Text>
          </Alert>
        )}

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.footer.icons")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.home.footer.icons")}
            height="48px" borderRadius={4} pt={2} multiple
            {...register("icons")}
          />
          {Array.isArray(getValues("icons")) && getValues("icons").map((el, idx) => el ? (
            <React.Fragment key={idx}>
              <Image mt={2} src={el} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue(`icons.${idx}`, null)}>
                {t("general.delete")}
              </Button>
            </React.Fragment>
          ) : null)}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.footer.title1")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.footer.title1")}
            height="48px" borderRadius={4}
            {...register("title1", {
              required: t("validation.required")
            })}
          />
          {errors.title1 && (
            <FormHelperText color="red.600">{errors.title1.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.footer.title2")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.footer.title2")}
            height="48px" borderRadius={4}
            {...register("title2", {
              required: t("validation.required")
            })}
          />
          {errors.title2 && (
            <FormHelperText color="red.600">{errors.title2.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.footer.title3")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.footer.title3")}
            height="48px" borderRadius={4}
            {...register("title3", {
              required: t("validation.required")
            })}
          />
          {errors.title3 && (
            <FormHelperText color="red.600">{errors.title3.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.footer.title4")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.footer.title4")}
            height="48px" borderRadius={4}
            {...register("title4", {
              required: t("validation.required")
            })}
          />
          {errors.title4 && (
            <FormHelperText color="red.600">{errors.title4.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.footer.image")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.home.footer.image")}
            height="48px" borderRadius={4} pt={2}
            {...register("footerImage")}
          />
          {typeof (getValues("footerImage")) === "string" && getValues("footerImage") && (
            <>
              <Image mt={2} src={getValues("footerImage")} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("footerImage", null)}>
                {t("general.delete")}
              </Button>
            </>
          )}
        </FormControl>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          textTransform="capitalize"
          _hover={{ bg: "green.600" }}
        >
          {t("general.save")}
        </Button>
      </Stack>
    </form>
  )
}

export default Footer