import React from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../../shared/table/Table';

const GTable = ({ data, page }) => {
  const { t } = useTranslation();
  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>{t('pages.contacts.name')}</th>
          <th>{t('pages.contacts.email')}</th>
          <th>{t('pages.contacts.phone')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{((page - 1) * 10) + (index + 1)}</td>
            <td>{el.name}</td>
            <td>{el.email}</td>
            <td>{el.phone}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default GTable