import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";
import { filterRequest } from "src/utilities/filterRequest";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  itemsCount: 0,
  error: null,
}

export const getServices = createAsyncThunk(
  "services/getServices",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = {} } = args;
      const filterReq = filterRequest(filter);
      const { data } = await axiosInstace.get(`/servicesIncome?page=${page}&size=${size}&query=${query}${filterReq}`,);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createService = createAsyncThunk(
  "services/createService",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/servicesIncome", args);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateService = createAsyncThunk(
  "services/updateService",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put(`/servicesIncome/${args._id}`, args.values);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteService = createAsyncThunk(
  "services/deleteService",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.delete(`/servicesIncome/${args._id}`);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
)

const servicesSlice = createSlice({
  name: "services",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getServices.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getServices.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = payload.data.data;
        state.pages = payload.data.pages;
        state.itemsCount = payload.data.itemsCount;
      })
      .addCase(getServices.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(createService.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createService.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data.push(payload.data);
        state.itemsCount++;
      })
      .addCase(createService.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateService.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateService.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.isLoading = false;
        state.error = null;
        const indexAt = state.data?.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
      })
      .addCase(updateService.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(deleteService.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteService.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = state.data.filter(el => el._id !== payload.data._id);
      })
      .addCase(deleteService.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
  }
});

export default servicesSlice.reducer;