import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Alert,
  AlertIcon,
  Text,
  useToast,
  Image,
} from "@chakra-ui/react";
import { createOfferSettings, updateOfferSettings } from "src/store/settings/services/servicesSettingsSlice";

const Offer = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const dispatch = useDispatch();
  const servicesSettings = useSelector(state => state.servicesSettings);
  const offerSettings = servicesSettings.data?.offerSection || {};

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
    handleSubmit
  } = useForm({
    defaultValues: offerSettings
  });

  watch(["video", "background", "image"]);

  return (
    <form onSubmit={handleSubmit(values => {
      delete values._id;
      const formData = new FormData();
      formData.set("title", values.title);
      formData.set("subtitle", values.subtitle);
      formData.set("description", values.description);
      formData.set("offerPercentage", values.offerPercentage);
      formData.set("video", typeof (values.video) === "string" ? values.video : values.video[0]);
      formData.set("background", typeof (values.background) === "string" ? values.background : values.background[0]);
      formData.set("image", typeof (values.image) === "string" ? values.image : values.image[0]);

      dispatch(offerSettings ? updateOfferSettings(formData) : createOfferSettings(formData))
        .unwrap()
        .then(_ => {
          toast({ description: "settings updated" });
        })
        .catch(e => {
          window.scrollTo(0, 0);
        })
    })}>
      <Stack spacing={6}>
        {servicesSettings.error && (
          <Alert status="error" variant="left-accent">
            <AlertIcon />
            <Text>{servicesSettings.error || "-"}</Text>
          </Alert>
        )}

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.offer.title")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.services.offer.title")}
            height="48px" borderRadius={4}
            {...register("title", {
              required: t("validation.required")
            })}
          />
          {errors.title && (
            <FormHelperText color="red.600">{errors.title.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.offer.subtitle")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.services.offer.subtitle")}
            height="48px" borderRadius={4}
            {...register("subtitle", {
              required: t("validation.required")
            })}
          />
          {errors.subtitle && (
            <FormHelperText color="red.600">{errors.subtitle.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.offer.description")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.services.offer.description")}
            height="48px" borderRadius={4}
            {...register("description", {
              required: t("validation.required")
            })}
          />
          {errors.description && (
            <FormHelperText color="red.600">{errors.description.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.offer.offer_percentage")}
          </FormLabel>
          <Input
            type="number" placeholder={t("pages.settings.services.offer.offer_percentage")}
            height="48px" borderRadius={4}
            {...register("offerPercentage", {
              required: t("validation.required")
            })}
          />
          {errors.offerPercentage && (
            <FormHelperText color="red.600">{errors.offerPercentage.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.offer.video")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.services.offer.video")}
            height="48px" borderRadius={4} pt={2}
            {...register("video")}
          />
          {typeof (getValues("video")) === "string" && getValues("video") && (
            <>
              <Image mt={2} src={getValues("video")} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("video", null)}>
                {t("general.delete")}
              </Button>
            </>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.offer.offer_section_background")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.services.offer.offer_section_background")}
            height="48px" borderRadius={4} pt={2}
            {...register("background")}
          />
          {typeof (getValues("background")) === "string" && getValues("background") && (
            <>
              <Image mt={2} src={getValues("background")} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("background", null)}>
                {t("general.delete")}
              </Button>
            </>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.services.offer.offer_section_image")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.services.offer.offer_section_image")}
            height="48px" borderRadius={4} pt={2}
            {...register("image")}
          />
          {typeof (getValues("image")) === "string" && getValues("image") && (
            <>
              <Image mt={2} src={getValues("image")} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("image", null)}>
                {t("general.delete")}
              </Button>
            </>
          )}
        </FormControl>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          textTransform="capitalize"
          _hover={{ bg: "green.600" }}
        >
          {t("general.save")}
        </Button>
      </Stack>
    </form>
  )
}

export default Offer