import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  itemsCount: 0,
  error: null,
}

export const getClients = createAsyncThunk(
  "clients/getClients",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const { data } = await axiosInstace.get(`/clients?page=${page}&size=${size}&query=${query}`,);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createClient = createAsyncThunk(
  "clients/createClient",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("clients", args);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateClient = createAsyncThunk(
  "clients/updateClient",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put(`/clients/${args._id}`, args.values);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteClient = createAsyncThunk(
  "clients/deleteClient",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.delete(`/clients/${args._id}`);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
)

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getClients.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getClients.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = payload.data.data;
        state.pages = payload.data.pages;
        state.itemsCount = payload.data.itemsCount;
      })
      .addCase(getClients.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(createClient.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createClient.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data.push(payload.data);
        state.itemsCount++;
      })
      .addCase(createClient.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateClient.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateClient.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.isLoading = false;
        state.error = null;
        const indexAt = state.data?.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
      })
      .addCase(updateClient.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(deleteClient.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteClient.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = state.data.filter(el => el._id !== payload.data._id);
      })
      .addCase(deleteClient.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
  }
});

export default clientsSlice.reducer;