import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal, ModalOverlay,
  ModalContent, ModalHeader,
  ModalFooter, ModalBody,
  Button, FormControl,
  FormLabel, Input,
  Textarea,
  Flex, Text,
  Alert,
  AlertIcon,
  Box,
  Stack,
  useToast
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';

import { createBlog } from '../../../../store/blogs/blogsSlice';

import theme from '../../../global/theme';

const CreateModal = ({ onClose }) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const blogs = useSelector(state => state.blogs);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  return (
    <Modal isOpen={true} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form onSubmit={handleSubmit((values, event) => {
          const formData = new FormData();

          for (let prop in values) {
            if (prop === "image") {
              formData.set(prop, values[prop][0]);
            } else {
              formData.set(prop, values[prop]);
            }
          }

          dispatch(createBlog(formData))
            .unwrap()
            .then(res => {
              toast({ description: "item created" });
              onClose();
            }).catch(e => {
              console.log(e);
            })
        })}>
          <ModalHeader textAlign="center" color="orange" textTransform="uppercase" fontSize={22}>
            {t('general.create')}
          </ModalHeader>
          <ModalBody>

            {blogs.error && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                <Text>{blogs.error}</Text>
              </Alert>
            )}

            <Stack spacing={6}>
              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.blogs.title')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.blogs.title')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("title", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.title?.message &&
                  <Text color="red.600" marginTop={2}>{errors.title.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.blogs.date')}
                </FormLabel>
                <Input type="date" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.blogs.date')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("date", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.date?.message &&
                  <Text color="red.600" marginTop={2}>{errors.date.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.blogs.image')}
                </FormLabel>
                <Input type="file" pt={1} bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.blogs.image')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("image", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.image?.message &&
                  <Text color="red.600" marginTop={2}>{errors.date.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.blogs.pinned')}
                </FormLabel>
                <Flex>

                  <input type="checkbox" color={theme.light}
                    {...register("pinned")} />
                  <Box ms={2} color={theme.light}>{t('pages.blogs.pinned')}</Box>
                </Flex>
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.blogs.badge')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.blogs.badge')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("badge", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.badge?.message &&
                  <Text color="red.600" marginTop={2}>{errors.badge.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.blogs.description')}
                </FormLabel>
                <Textarea bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.blogs.description')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("description")}
                />
                {errors.description?.message &&
                  <Text color="red.600" marginTop={2}>{errors.description.message}</Text>}
              </FormControl>
            </Stack>
          </ModalBody >
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white" bg="green" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                isLoading={blogs.isLoading}
                _hover={{ background: 'green' }}
              >
                {t('general.save')}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white" bg="red.600" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                marginInlineStart={4}
                isLoading={blogs.isLoading}
                _hover={{ background: 'red.600' }}
              >
                {t('general.close')}
              </Button>
            </Flex>
          </ModalFooter>
        </form >
      </ModalContent >
    </Modal >
  )
}

export default CreateModal