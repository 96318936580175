import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Alert,
  AlertIcon,
  Text,
  useToast,
} from "@chakra-ui/react";
import { updateVideoSettings } from "src/store/settings/home/homeSettingsSlice";

const Video = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const dispatch = useDispatch();
  const homeSettings = useSelector(state => state.homeSettings);
  const videoSettings = homeSettings.data?.videoSection || {};

  const {
    register,
    getValues,
    handleSubmit
  } = useForm({
    defaultValues: videoSettings
  });

  return (
    <form onSubmit={handleSubmit(values => {
      delete values._id;
      console.log(values);
      const formData = new FormData();
      for (let prop in values) {
        if (prop === "video") {
          formData.set(prop, values[prop][0])
        } else {
          formData.set(prop, values[prop])
        }
      }
      dispatch(updateVideoSettings(formData))
        .unwrap()
        .then(_ => {
          toast({ description: "settings updated" });
        })
        .catch(e => {
          window.scrollTo(0, 0);
        })
    })}>
      <Stack spacing={6}>
        {homeSettings.error && (
          <Alert status="error" variant="left-accent">
            <AlertIcon />
            <Text>{homeSettings.error || "-"}</Text>
          </Alert>
        )}

        {getValues("video") && (
          <video src={videoSettings.video} controls style={{ height: "250px" }} />
        )}

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.video.video")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.home.video.video")}
            height="48px" borderRadius={4} pt={2}
            {...register("video")}
          />
        </FormControl>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          textTransform="capitalize"
          _hover={{ bg: "green.600" }}
        >
          {t("general.save")}
        </Button>
      </Stack>
    </form>
  )
}

export default Video