import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Alert,
  AlertIcon,
  Text,
  useToast,
  Textarea,
  Image,
  Box,
} from "@chakra-ui/react";
import { createPortofolioSettings, updatePortofolioSettings } from "src/store/settings/home/homeSettingsSlice";
import React from "react";

const Portofolio = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const dispatch = useDispatch();
  const homeSettings = useSelector(state => state.homeSettings);
  const portofolioSettings = homeSettings.data?.portfolioSection || null;

  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    watch,
    handleSubmit
  } = useForm({
    defaultValues: portofolioSettings || {}
  });

  watch(["portfolioImages"]);

  return (
    <form onSubmit={handleSubmit(values => {
      const formData = new FormData();

      formData.set("title", values.title);
      formData.set("description", values.description);

      if (Array.isArray(values.portfolioImages)) {
        for (let i = 0; i < values.portfolioImages.length; i++) {
          if (values.portfolioImages[i]) {
            formData.append("portfolioImages[]", values.portfolioImages[i]);
          }
        }
      } else {
        for (let i = 0; i < values.portfolioImages.length; i++) {
          if (values.portfolioImages[i]) {
            formData.append("portfolioImages", values.portfolioImages[i]);
          }
        }
      }

      dispatch(portofolioSettings ? updatePortofolioSettings(formData) : createPortofolioSettings(formData))
        .unwrap()
        .then(_ => {
          toast({ description: "settings updated" });
        })
        .catch(e => {
          window.scrollTo(0, 0);
        })
    })}>
      <Stack spacing={6}>
        {homeSettings.error && (
          <Alert status="error" variant="left-accent">
            <AlertIcon />
            <Text>{homeSettings.error || "-"}</Text>
          </Alert>
        )}

        {getValues("portofolioImages") && getValues("portofolioImages").length > 0 && (
          portofolioSettings.portofolioImages.map((el, idx) => (
            <Box key={idx} margin={2} border="1px solid #ccc" width="150px">
              <Image src={el} key={idx} height="150px" width="100%" />
            </Box>
          ))
        )}

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.portofolio.title")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.home.portofolio.title")}
            height="48px" borderRadius={4}
            {...register("title", {
              required: t("validation.required")
            })}
          />
          {errors.title && (
            <FormHelperText color="red.600">{errors.title.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.portofolio.description")}
          </FormLabel>
          <Textarea
            placeholder={t("pages.settings.home.portofolio.description")}
            borderRadius={4} resize="none"
            {...register("description", {
              required: t("validation.required")
            })}
          />
          {errors.description && (
            <FormHelperText color="red.600">{errors.description.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.home.portofolio.images")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.home.portofolio.images")}
            height="48px" borderRadius={4} pt={2} multiple
            {...register("portfolioImages")}
          />
          {Array.isArray(getValues("portfolioImages")) && getValues("portfolioImages").map((el, idx) => el ? (
            <React.Fragment key={idx}>
              <Image mt={2} src={el} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue(`portfolioImages.${idx}`, null)}>
                {t("general.delete")}
              </Button>
            </React.Fragment>
          ) : null)}
        </FormControl>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          textTransform="capitalize"
          _hover={{ bg: "green.600" }}
        >
          {t("general.save")}
        </Button>
      </Stack>
    </form>
  )
}

export default Portofolio