import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal, ModalOverlay,
  ModalContent, ModalHeader,
  ModalFooter, ModalBody,
  Button, FormControl,
  FormLabel, Input,
  Flex, Text,
  Alert,
  AlertIcon,
  Stack,
  useToast,
  Box
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';
import Search from "src/components/shared/search/Search";

import { createPlan } from '../../../../store/plans/plansSlice';

import theme from '../../../global/theme';
import { getFeatures } from "src/store/features/featuresSlice";

const CreateModal = ({ onClose }) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const dispatch = useDispatch();
  const plans = useSelector(state => state.plans);
  const features = useSelector(state => state.features);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useForm();

  watch(["features"]);

  const { fields: featuresField, append: appendFeature, remove: removeFeature } = useFieldArray({
    control,
    name: "features"
  });

  useEffect(() => {
    dispatch(getFeatures({ page: 1, size: 10 }));
  }, [dispatch]);

  return (
    <Modal isOpen={true} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form onSubmit={handleSubmit(values => {
          values.features = values.features?.map(el => el._id);
          dispatch(createPlan(values))
            .unwrap()
            .then(res => {
              toast({ description: "item created" });
              onClose();
            }).catch(e => {
              console.log(e);
            })
        })}>
          <ModalHeader textAlign="center" color="orange" textTransform="uppercase" fontSize={22}>
            {t('general.create')}
          </ModalHeader>
          <ModalBody>

            {plans.error && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                <Text>{plans.error}</Text>
              </Alert>
            )}

            <Stack spacing={6}>
              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.plans.name')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.plans.name')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("name", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.name?.message &&
                  <Text color="red.600" marginTop={2}>{errors.name.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.plans.amount')}
                </FormLabel>
                <Input type="number" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.plans.amount')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("amount", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.amount?.message &&
                  <Text color="red.600" marginTop={2}>{errors.amount.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.plans.cycloroma')}
                </FormLabel>
                <Input type="number" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.plans.cycloroma')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("cycloroma", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.cycloroma?.message &&
                  <Text color="red.600" marginTop={2}>{errors.cycloroma.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.plans.from')}
                </FormLabel>
                <Input type="number" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.plans.from')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("duration.from", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.duration?.from?.message &&
                  <Text color="red.600" marginTop={2}>{errors.duration.from.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.plans.to')}
                </FormLabel>
                <Input type="number" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.plans.to')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("duration.to", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.duration?.to?.message &&
                  <Text color="red.600" marginTop={2}>{errors.duration.to.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.plans.features')}
                </FormLabel>
                {featuresField.map((feature, idx) => (
                  <Flex gap={2} key={feature.id} mb={2} alignItems="center">
                    <Box flex="1">
                      <Search
                        page={1}
                        itemsCount={features.itemsCount}
                        data={features.data}
                        allowPagination={false}
                        allowSearch={false}
                        prop="name"
                        currentElement={getValues(`features.${idx}`)}
                        handleSelected={(item) => { setValue(`features.${idx}`, item); console.log(item) }}
                      />
                    </Box>
                    <Button
                      type="button" bg="green.600" color="white" size="sm"
                      onClick={() => appendFeature("")}
                    >
                      <FiIcons.FiPlus size={16} />
                    </Button>
                    <Button
                      type="button" bg="red.600" color="white" size="sm"
                      onClick={() => removeFeature(idx)}
                    >
                      <FiIcons.FiMinus size={16} />
                    </Button>
                  </Flex>
                ))}
                {featuresField.length === 0 && (
                  <Button type="button" width="100%" onClick={() => appendFeature("")}>
                    <FiIcons.FiPlus size={16} />
                  </Button>
                )}
              </FormControl>

            </Stack>
          </ModalBody >
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white" bg="green" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                isLoading={plans.isLoading}
                _hover={{ background: 'green' }}
              >
                {t('general.save')}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white" bg="red.600" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                marginInlineStart={4}
                isLoading={plans.isLoading}
                _hover={{ background: 'red.600' }}
              >
                {t('general.close')}
              </Button>
            </Flex>
          </ModalFooter>
        </form >
      </ModalContent >
    </Modal >
  )
}

export default CreateModal