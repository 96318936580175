import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Alert,
  AlertIcon,
  Text,
  useToast,
  Image,
} from "@chakra-ui/react";
import { createBlogsSettings, updateBlogsSettings } from "src/store/settings/blogs/blogsSettingsSlice";

const Main = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const dispatch = useDispatch();
  const blogsSettings = useSelector(state => state.blogsSettings);

  const {
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
    handleSubmit
  } = useForm({
    defaultValues: blogsSettings.data
  });

  watch(["backgroundImage"]);

  return (
    <form onSubmit={handleSubmit(values => {
      const formData = new FormData();

      formData.set("title", values.title);
      if (Array.isArray(values.backgroundImage)) {
        formData.set("backgroundImage", values.backgroundImage[0]);
      } else {
        formData.set("backgroundImage", values.backgroundImage);
      }

      dispatch(blogsSettings.data ? updateBlogsSettings(formData) : createBlogsSettings(formData))
        .unwrap()
        .then(_ => {
          toast({ description: "settings updated" });
        })
        .catch(e => {
          window.scrollTo(0, 0);
        })
    })}>
      <Stack spacing={6}>
        {blogsSettings.error && (
          <Alert status="error" variant="left-accent">
            <AlertIcon />
            <Text>{blogsSettings.error || "-"}</Text>
          </Alert>
        )}

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.blogs.main.title")}
          </FormLabel>
          <Input
            type="text" placeholder={t("pages.settings.blogs.main.title")}
            height="48px" borderRadius={4}
            {...register("title", {
              required: t("validation.required")
            })}
          />
          {errors.title && (
            <FormHelperText color="red.600">{errors.title.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel textTransform="capitalize">
            {t("pages.settings.blogs.main.background_image")}
          </FormLabel>
          <Input
            type="file" placeholder={t("pages.settings.blogs.main.background_image")}
            height="48px" borderRadius={4} pt={2}
            {...register("backgroundImage")}
          />
          {typeof (getValues("backgroundImage")) === "string" && getValues("backgroundImage") && (
            <>
              <Image mt={2} src={getValues("backgroundImage")} height="150px" objectFit="contain" />
              <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("backgroundImage", null)}>
                {t("general.delete")}
              </Button>
            </>
          )}
        </FormControl>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          textTransform="capitalize"
          _hover={{ bg: "green.600" }}
        >
          {t("general.save")}
        </Button>
      </Stack>
    </form>
  )
}

export default Main