import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: {},
  error: null,
}

export const getAboutSettings = createAsyncThunk(
  "aboutSettings/getAboutSettings",
  async (_, thunkApi) => {
    try {
      const { data } = await axiosInstace.get("/aboutUs");
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const createAboutSettings = createAsyncThunk(
  "aboutSettings/createAboutSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("/aboutUs", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateAboutSettings = createAsyncThunk(
  "aboutSettings/updateAboutSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put("/aboutUs", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const aboutSettingsSlice = createSlice({
  name: "aboutSettings",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAboutSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAboutSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(getAboutSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(createAboutSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createAboutSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(createAboutSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })

      .addCase(updateAboutSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateAboutSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(updateAboutSettings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.message;
      })
  }
});

export default aboutSettingsSlice.reducer;