import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import {
  Modal, ModalOverlay,
  ModalContent, ModalHeader,
  ModalFooter, ModalBody,
  Button, FormControl,
  FormLabel, Input,
  Flex, Text,
  Stack,
  useToast,
  Image,
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';
import axios from "src/utilities/axios";
import theme from "src/components/global/theme";


const ServiceModal = ({ onClose, service }) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const [isLoading, setIsLoading] = useState(false);

  console.log(1);

  function formatText(inputText) {
    // Check if the input text contains only one word
    if (/^\S+$/.test(inputText)) {
      return inputText; // Return unchanged if only one word
    }

    // Replace spaces with hyphens
    const formattedText = inputText.replace(/\s+/g, '-');
    return formattedText;
  }

  const serviceName = formatText(service);

  console.log(serviceName);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/services/${serviceName}`);
      if (data?.data) {
        reset({
          title: data?.data?.title,
          background: data?.data?.background,
          option1Image: data?.data?.option1?.image,
          option2Image: data?.data?.option2?.image,
          option1: data?.data?.option1,
          option2: data?.data?.option2,
        });
      }
      setIsLoading(false);
    } catch (e) {
      toast({ description: "something went wrong", status: "error" });
      onClose();
    }
  }

  const updateService = async (data) => {
    try {
      await axios.put(`/services/${serviceName}`, data);
      toast({ description: "item updated" });
    } catch (e) {
      toast({ description: e.message, status: "error" });
    } finally {
      onClose();
    }
  }

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      option1: {
        name: "",
        title: "",
        features: [""]
      },
      option2: {
        name: "",
        title: "",
        features: [""]
      }
    }
  });

  watch(["image", "background", "option1Image", "option2Image", "option1.features", "option2.features"]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={true} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        {/* {isLoading && <Spinner />} */}
        <form onSubmit={handleSubmit(async values => {
          const formData = new FormData();

          formData.set("title", values.title);
          formData.set("background", values.background);

          // option1
          formData.append("option1[name]", values.option1.name);
          formData.append("option1[title]", values.option1.title);
          if (typeof (values.option1Image) === "string") {
            formData.append("option1[image]", values.option1Image);
          } else {
            formData.set("option1Image", values.option1Image[0]);
          }
          for (let i = 0; i < values.option1.features.length; i++) {
            const feature = values.option1.features[i];
            formData.append(`option1[features][${i}]`, feature);
          }

          // option2
          formData.append("option2[name]", values.option2.name);
          formData.append("option2[title]", values.option2.title);
          if (typeof (values.option2Image) === "string") {
            formData.append("option2[image]", values.option2Image);
          } else {
            formData.set("option2Image", values.option2Image[0]);
          }
          for (let i = 0; i < values.option2.features.length; i++) {
            const feature = values.option2.features[i];
            formData.append(`option2[features][${i}]`, feature);
          }

          await updateService(formData)
        })}>
          <ModalHeader textAlign="center" color="orange" textTransform="uppercase" fontSize={22}>
            {t('general.create')}
          </ModalHeader>
          <ModalBody>

            <Stack spacing={6}>
              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.settings.services.all_services.title')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.settings.services.all_services.title')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("title", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.title?.message &&
                  <Text color="red.600" marginTop={2}>{errors.title.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.settings.services.all_services.background')}
                </FormLabel>
                <Input type="file" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.settings.services.all_services.background')} _placeholder={{ textTransform: 'capitalize' }}
                  pt={1} {...register("background")}
                />
                {typeof (getValues("background")) === "string" && getValues("background") && (
                  <>
                    <Image mt={2} src={getValues("background")} height="150px" objectFit="contain" />
                    <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("background", null)}>
                      {t("general.delete")}
                    </Button>
                  </>
                )}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.settings.services.all_services.option1_image')}
                </FormLabel>
                <Input type="file" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.settings.services.all_services.option1_image')} _placeholder={{ textTransform: 'capitalize' }}
                  pt={1} {...register("option1Image")}
                />
                {typeof (getValues("option1Image")) === "string" && getValues("option1Image") && (
                  <>
                    <Image mt={2} src={getValues("option1Image")} height="150px" objectFit="contain" />
                    <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("option1Image", null)}>
                      {t("general.delete")}
                    </Button>
                  </>
                )}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.settings.services.all_services.option2_image')}
                </FormLabel>
                <Input type="file" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.settings.services.all_services.option2_image')} _placeholder={{ textTransform: 'capitalize' }}
                  pt={1} {...register("option2Image")}
                />
                {typeof (getValues("option2Image")) === "string" && getValues("option2Image") && (
                  <>
                    <Image mt={2} src={getValues("option2Image")} height="150px" objectFit="contain" />
                    <Button mt={2} bg="red.600" color="white" type="button" onClick={() => setValue("option2Image", null)}>
                      {t("general.delete")}
                    </Button>
                  </>
                )}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.settings.services.all_services.option1_title')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.settings.services.all_services.option1_title')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("option1.title", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors["option1"] && errors["option1"].title?.message &&
                  <Text color="red.600" marginTop={2}>{errors["option1"].title.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.settings.services.all_services.option2_title')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.settings.services.all_services.option2_title')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("option2.title", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors["option2"] && errors["option2"].title?.message &&
                  <Text color="red.600" marginTop={2}>{errors["option2"].title.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.settings.services.all_services.option1_name')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.settings.services.all_services.option1_name')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("option1.name", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors["option1"] && errors["option1"].name?.message &&
                  <Text color="red.600" marginTop={2}>{errors["option1"].name.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.settings.services.all_services.option2_name')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.settings.services.all_services.option2_name')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("option2.name", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors["option2"] && errors["option2"].name?.message &&
                  <Text color="red.600" marginTop={2}>{errors["option2"].name.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.settings.services.all_services.option1_features')}
                </FormLabel>
                {getValues("option1.features").map((el, idx) => (
                  <Flex gap={4} mb={3} key={idx}>
                    <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                      placeholder={t('pages.settings.services.all_services.option1_features')} _placeholder={{ textTransform: 'capitalize' }}
                      {...register(`option1.features.${idx}`, {
                        required: `${t('validation.required')}`
                      })}
                    />
                    <Button type="button" onClick={() => {
                      setValue("option1.features", [...getValues("option1.features"), ""]);
                    }}>
                      <FiIcons.FiPlus size={16} />
                    </Button>
                  </Flex>
                ))}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.settings.services.all_services.option2_features')}
                </FormLabel>
                {getValues("option2.features").map((el, idx) => (
                  <Flex gap={4} mb={3} key={idx}>
                    <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                      placeholder={t('pages.settings.services.all_services.option2_features')} _placeholder={{ textTransform: 'capitalize' }}
                      {...register(`option2.features.${idx}`, {
                        required: `${t('validation.required')}`
                      })}
                    />
                    <Button type="button" onClick={() => {
                      setValue("option2.features", [...getValues("option2.features"), ""]);
                    }}>
                      <FiIcons.FiPlus size={16} />
                    </Button>
                  </Flex>
                ))}
              </FormControl>
            </Stack>

          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white" bg="green" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                isLoading={isLoading}
                _hover={{ background: 'green' }}
              >
                {t('general.save')}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white" bg="red.600" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                marginInlineStart={4}
                isLoading={isLoading}
                _hover={{ background: 'red.600' }}
              >
                {t('general.close')}
              </Button>
            </Flex>
          </ModalFooter>
        </form >
      </ModalContent>
    </Modal >
  )
}

export default ServiceModal